class Graph {
	constructor() {
		this.edges = [];
		this.vertices = [];
	}

	addVertex(vertex) {
		return this.vertices.push(vertex) - 1;
	}

	deleteVertex(handle) {
		this.getVertexEdges(handle).forEach((edge) => {
			this.deleteEdge(edge);
		});

		delete this.vertices[handle];
	}

	getVertexEdges(handle) {
		const result = [];

		this.edges.forEach((edge, edgeHandle) => {
			if (edge.from === handle || edge.to === handle) {
				result.push(edgeHandle);
			}
		});

		return result;
	}

	getEdgeCount(handle) {
		const edges = this.getVertexEdges(handle);
		return edges.length;
	}

	getInEdges(handle) {
		const candidates = this.getVertexEdges(handle);

		return candidates.filter((edge) => {
			if (this.edges[edge].to === handle) {
				return true;
			}

			return false;
		});
	}

	getOutEdges(handle) {
		const candidates = this.getVertexEdges(handle);

		return candidates.filter((edge) => {
			if (this.edges[edge].from === handle) {
				return true;
			}

			return false;
		});
	}

	addEdge(edge) {
		if (!this.vertices[edge.from]) {
			throw new Error();
		}
		if (!this.vertices[edge.to]) {
			throw new Error();
		}

		return this.edges.push({
			from: edge.from,
			to: edge.to,
			weight: edge.weight || 0
		}) - 1;
	}

	deleteEdge(handle) {
		delete this.edges[handle];
	}

	/**
	 * Finds the shortest path between two vertices using Dijkstra's algorithm.
	 * @param {Number} from The handle of the start vertex.
	 * @param {Number} to The handle of the end vertex.
	 * @return {Array<Number>} An array of edge handled describing the path
	 * from the start to the end vertex.
	 */
	shortestPath(from, to) {
		const distances = {};
		const shortestEdgeTo = {};
		const unvisited = {};
		let path = [];

		// Fill with initial distances:
		this.vertices.forEach((vertex, handle) => {
			if (handle === from) {
				distances[handle] = 0;
			} else {
				distances[handle] = Infinity;
			}
			unvisited[handle] = true;
		});

		while (Object.keys(unvisited).length) {
			// Find the unvisited with the shortest distance to the current
			let current = Object.keys(unvisited).reduce((previous, current) => {
				if (distances[current] < distances[previous]) {
					return current;
				}

				return previous;
			});

			current = parseInt(current, 10); // Object keys are strings apperently.

			// Iterate over all unvisited target vertices of the
			// current vertex' out edges:
			const candidateEdges = this.getOutEdges(current).filter((handle) => {
				const edge = this.edges[handle];
				const targetVertex = edge.to;

				return targetVertex in unvisited;
			});

			candidateEdges.forEach((handle) => {
				const edge = this.edges[handle];
				const targetVertex = edge.to;
				const distance = distances[current] + edge.weight;

				if (distance < distances[targetVertex]) {
					distances[targetVertex] = distance;
					shortestEdgeTo[targetVertex] = handle;
				}
			});

			// We've reached the target vertex. Build an edge path
			// and stop looking:
			if (current === to) {
				while (current in shortestEdgeTo) {
					path = [shortestEdgeTo[current]].concat(path);
					current = this.edges[shortestEdgeTo[current]].from;
				}
				break;
			}

			// We're checked all paths to the current vertex, so
			// mark it as visited:
			delete unvisited[current];

			// No path can be found to the target vertex:
			if (distances[current] === Infinity) {
				console.log('No path found.');
				break;
			}
		}

		return path;
	}
}

module.exports = Graph;


class Timer {
	constructor(config) {
		config = config || {};

		this.lastTick = performance.now();
		this.currentTick = performance.now();
		this.accumulatedSimTime = 0;
		this.cpuReliefFactor = 1;
		this.dtHistory = [];

		// Settings:
		this.timeMultiplier = 1;
		this.updateFrequency = config.frequency || 120; // Hz
		this.maxHistoryTickCount = 60;
		this.cpuReliefDtThreshold = 1 / 30; // 30 FPS

		this.tick();
	}

	averagedTickTime() {
		return this.dtHistory.reduce((a, b) => {
			return a + b;
		}) / this.dtHistory.length;
	}

	tickTime() {
		return this.dtHistory[this.dtHistory.length - 1];
	}

	simTickTime() {
		return 1 / this.updateFrequency;
	}

	simTickCount() {
		return Math.floor(this.accumulatedSimTime / this.simTickTime());
	}

	tick() {
		let dt = 0;

		// Update tick time:
		this.lastTick = this.currentTick;
		this.currentTick = performance.now();
		dt = (this.currentTick / 1000) - (this.lastTick / 1000);

		this.dtHistory.push(dt);
		if (this.dtHistory.length > this.maxHistoryTickCount) {
			this.dtHistory.shift();
		}

		// Remove last frame's sim time from the accumulated sim time before
		// adding this frame's:
		this.accumulatedSimTime -= this.simTickCount() * this.simTickTime();

		// Attempt to adjust the gametime if the simulation takes too long:
		if (dt > this.cpuReliefDtThreshold) {
			this.cpuReliefFactor *= 0.95;
		} else if (this.cpuReliefFactor < 1) {
			this.cpuReliefFactor = Math.max(1, this.cpuReliefFactor * 1.05);
		}

		this.accumulatedSimTime += dt * this.timeMultiplier * this.cpuReliefFactor;
	}
}

export default Timer;

import ControllerMode from '../ControllerMode';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';

class TrainRouteComponent extends Component {
	static propTypes = {
		handle: PropTypes.number.isRequired,
		renderState: PropTypes.object.isRequired,
		mode: PropTypes.number.isRequired,
		modeContext: PropTypes.object,
		onSetMode: PropTypes.func.isRequired,
		onTrainRemoveDestination: PropTypes.func.isRequired
	};

	handleAddDestination = () => {
		this.props.onSetMode(
			ControllerMode.ADD_TRAIN_DESTINATION,
			{
				handle: this.props.handle
			}
		);
	};

	render() {
		const handle = this.props.handle;
		const train = this.props.renderState.trains[handle];
		let addingDestination = false;

		if (this.props.mode === ControllerMode.ADD_TRAIN_DESTINATION &&
		    this.props.modeContext.handle === handle
		) {
			addingDestination = true;
		}

		return (
			<Fragment>
				<table className="table">
					<thead>
						<tr>
							<th>Destination</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{train.route.map((target, index) => {
							let destination = target;

							if (train.routeIndex === index) {
								destination = (
									<strong>{destination}</strong>
								);
							}

							return (
								<tr key={index}>
									<td>{destination}</td>
									<td>
										<button
											className="btn btn-link"
											onClick={() => this.props.onTrainRemoveDestination(handle, index)}
										>
											Remove
										</button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<p>
					<button
						className={`btn btn-secondary ${addingDestination ? 'active' : ''}`}
						onClick={this.handleAddDestination}
					>
						Add destination
					</button>
				</p>
			</Fragment>
		);
	}
}

export default TrainRouteComponent;

import CanvasRenderer from './CanvasRenderer';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import TrainState from '../../TrainState';
import TrainStatistics from './TrainStatistics';
import TrainRoute from './TrainRoute';
import injectStyles from 'react-jss';

const styles = {
	viewport: {
		position: 'relative',
		height: '150px',
		overflow: 'hidden',

		backgroundColor: 'rgba(250, 250, 250, 1.0)',
		marginBottom: '8px'
	},

	viewportButtons: {
		composes: 'btn-group',
		position: 'absolute',
		top: '5px',
		right: '5px'
	},

	viewportButton: {
		composes: 'btn btn-secondary'
	},

	viewportButtonToggle: {
		composes: [
			'$viewportButton',
			'btn-toggle'
		]
	},

	viewportButtonToggleActive: {
		composes: [
			'$viewportButtonToggle',
			'active'
		]
	}
};

class TrainDetailsComponent extends Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		handle: PropTypes.number.isRequired,
		renderState: PropTypes.object.isRequired,
		selection: PropTypes.object,
		mode: PropTypes.number.isRequired,
		modeContext: PropTypes.object,
		onCenterViewport: PropTypes.func.isRequired,
		onSetMode: PropTypes.func.isRequired,
		onTrainRemoveDestination: PropTypes.func.isRequired,
		onTrainToggle: PropTypes.func.isRequired
	};

	render() {
		const {classes} = this.props;
		const handle = this.props.handle;
		const train = this.props.renderState.trains[handle];
		const carriagePoints = this.props.renderState.trainCarriagePoints[handle];
		const trainPosition = carriagePoints[1];

		return (
			<Fragment>
				<h3>Train {handle}</h3>
				<div className={classes.viewport}>
					<CanvasRenderer
						selection={this.props.selection}
						renderState={this.props.renderState}

						scale={4}
						center={trainPosition}
					/>
					<div className={classes.viewportButtons}>
						<button
							className={classes.viewportButton}
							onClick={() => {
								this.props.onCenterViewport(trainPosition);
							}}
						>
							<i className="fa fa-search"></i>
						</button>

						<button
							className={train.state !== TrainState.IDLE && train.state !== TrainState.STOP ? classes.viewportButtonToggleActive : classes.viewportButtonToggle}
							onClick={() => {
								this.props.onTrainToggle(handle);
							}}
						>
							<i className="fa fa-flag"></i>
						</button>
					</div>
				</div>

				<TrainStatistics
					handle={this.props.selection.handle}
					renderState={this.props.renderState}
				/>

				<h4>Route</h4>
				<TrainRoute
					handle={this.props.selection.handle}
					renderState={this.props.renderState}
					mode={this.props.mode}
					modeContext={this.props.modeContext}
					onSetMode={this.props.onSetMode}
					onTrainRemoveDestination={this.props.onTrainRemoveDestination}
				/>
			</Fragment>
		);
	}
}

export default injectStyles(styles)(TrainDetailsComponent);

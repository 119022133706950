import CanvasRenderer from './CanvasRenderer';
import IndustryDetails from './IndustryDetails';
import ItemType from '../../ItemType';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import StateList from './StateList';
import Tools from './Tools';
import TrainDetails from './TrainDetails';
import injectSheet from 'react-jss';

const styles = {
	interface: {
		display: 'flex',
		flex: {
			flow: 'column nowrap'
		},

		width: '100vw',
		height: '100vh'
	},

	header: {
		flex: {
			grow: 0,
			shrink: 0,
			basis: 'auto'
		}
	},

	main: {
		display: 'flex',

		flex: {
			grow: 1,
			shrink: 1,
			basis: 'auto',
			flow: 'row nowrap'
		}
	},

	sidebar: {
		flex: {
			grow: 0,
			shrink: 0,
			basis: 'auto'
		},

		width: '280px',
		padding: '15px',
		borderLeft: {
			width: '3px',
			style: 'solid',
			color: 'rgba(100, 150, 200, 1)'
		},

		overflowX: 'hidden',
		overflowY: 'scroll'
	},

	viewport: {
		flex: {
			grow: 1,
			shrink: 1,
			basis: 'auto'
		},

		position: 'relative',

		overflow: 'hidden',
		background: {
			color: 'rgba(250, 250, 250, 1.0)'
		}
	},

	viewportOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		overflow: 'hidden',

		fontFamily: [
			'courier',
			'monospace'
		],
		textShadow: [
			'0 0 5px  white',
			'0 0 10px white',
			'0 0 15px white',
			'0 0 20px white',
			'0 0 25px white'
		],

		padding: '0.5rem 1rem'
	}
};

class InterfaceComponent extends Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,

		selection: PropTypes.object,

		mode: PropTypes.number.isRequired,
		modeContext: PropTypes.object,

		renderState: PropTypes.object.isRequired,
		states: PropTypes.array.isRequired,

		averageDt: PropTypes.number.isRequired,
		updateFrequency: PropTypes.number.isRequired,
		timeMultiplier: PropTypes.number.isRequired,
		cpuReliefFactor: PropTypes.number.isRequired,
		accumulatedSimTime: PropTypes.number.isRequired,

		onStatesClear: PropTypes.func.isRequired,
		onStateAddCurrent: PropTypes.func.isRequired,
		onStateRemove: PropTypes.func.isRequired,
		onStateLoad: PropTypes.func.isRequired,
		onCenterViewport: PropTypes.func.isRequired,
		onTrainRemoveDestination: PropTypes.func.isRequired,
		onTrainToggle: PropTypes.func.isRequired,

		viewportScale: PropTypes.number.isRequired,
		viewportCenter: PropTypes.object.isRequired,

		onDeleteSelection: PropTypes.func.isRequired,
		onSelectIndustry: PropTypes.func.isRequired,
		onSelectTrain: PropTypes.func.isRequired,
		onTimeMultiplierDecrease: PropTypes.func.isRequired,
		onTimeMultiplierIncrease: PropTypes.func.isRequired,
		onSetMode: PropTypes.func.isRequired,

		onInteractStart: PropTypes.func.isRequired,
		onInteractUpdate: PropTypes.func.isRequired,
		onInteractEnd: PropTypes.func.isRequired,
		onInteractCancel: PropTypes.func.isRequired,
		onViewportTranslate: PropTypes.func.isRequired
	};

	render() {
		const {classes} = this.props;

		return (
			<div className={classes.interface}>
				<div className={classes.header}>
					<Tools
						enableDeletion={Boolean(this.props.selection)}
						industries={this.props.renderState.industries}
						mode={this.props.mode}
						trains={this.props.renderState.trains}

						onTimeMultiplierDecrease={this.props.onTimeMultiplierDecrease}
						onTimeMultiplierIncrease={this.props.onTimeMultiplierIncrease}
						onDeleteSelection={this.props.onDeleteSelection}
						onSelectIndustry={this.props.onSelectIndustry}
						onSelectTrain={this.props.onSelectTrain}
						onSetMode={this.props.onSetMode}
					/>
				</div>
				<div className={classes.main}>
					<div className={classes.viewport}>
						<CanvasRenderer
							selection={this.props.selection}
							renderState={this.props.renderState}
							scale={this.props.viewportScale}
							center={this.props.viewportCenter}

							onInteractStart={this.props.onInteractStart}
							onInteractUpdate={this.props.onInteractUpdate}
							onInteractEnd={this.props.onInteractEnd}
							onInteractCancel={this.props.onInteractCancel}

							onTranslate={this.props.onViewportTranslate}
						/>
						<div className={classes.viewportOverlay}>
							Rendering at {(1 / this.props.averageDt).toFixed(2)}Hz / {(this.props.averageDt * 1000).toFixed(2)}ms/frame, simulation at {this.props.updateFrequency}Hz x{(this.props.timeMultiplier * this.props.cpuReliefFactor).toFixed(3)} Sim behind: {this.props.accumulatedSimTime.toFixed(2)}ms
						</div>
					</div>
					<div className={classes.sidebar}>
						{
							this.props.selection &&
							this.props.selection.type === ItemType.TRAIN &&
							<TrainDetails
								handle={this.props.selection.handle}
								renderState={this.props.renderState}
								selection={this.props.selection}
								mode={this.props.mode}
								modeContext={this.props.modeContext}
								onCenterViewport={this.props.onCenterViewport}
								onTrainRemoveDestination={this.props.onTrainRemoveDestination}
								onTrainToggle={this.props.onTrainToggle}
								onSetMode={this.props.onSetMode}
							/>
						}
						{
							this.props.selection &&
							this.props.selection.type === ItemType.INDUSTRY &&
							<IndustryDetails
								handle={this.props.selection.handle}
								renderState={this.props.renderState}
								selection={this.props.selection}
								onCenterViewport={this.props.onCenterViewport}
							/>
						}

						<StateList
							states={this.props.states}
							onStateLoad={this.props.onStateLoad}
							onStateRemove={this.props.onStateRemove}
							onStateAddCurrent={this.props.onStateAddCurrent}
							onStatesClear={this.props.onStatesClear}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default injectSheet(styles)(InterfaceComponent);

import Bezier from '../engine/math/bezier.js';
import clone from 'clone';
import Graph from '../engine/math/Graph.js';
import Intersect from '../engine/math/intersect.js';
import ItemType from './ItemType';
import Scalar from '../engine/math/scalar.js';
import TrainState from './TrainState';
import Vector from '../engine/math/vector.js';

class Simulation {
	constructor() {
		this.carriageTypes = [];
		this.graph = new Graph();
		this.trains = [];
		this.industries = [];
		this.stations = {};

		// Hardcode some general state for now:
		this.carriageTypes.push({
			name: 'NSB El 17',
			capacity: 0, // items
			length: 16.3, // m
			width: 3.04, // m
			tractiveForce: 240000, // Newton
			mass: 64000, // kg
			itemMass: 0, // kg
			maxSpeed: 150 / 3.6 // m/s
		});

		this.carriageTypes.push({
			name: 'NSB B5',
			capacity: 55, // passengers
			length: 25.3, // m
			width: 3.10, // m
			tractiveForce: 0, // Newton
			mass: 42000, // kg
			itemMass: 70, // kg
			maxSpeed: 160 / 3.6 // m/s
		});

		this.buildState = {
			startVertex: null,
			startPosition: null,
			endVertex: null,
			endPostion: null
		};
		this.moveState = null;

		// Derived state (for optimization):
		this.edgeLengths = {};
		this.edgeReservations = {};
	}

	addDestination(trainHandle, edgeHandle) {
		this.trains[trainHandle].route.push(edgeHandle);
	}

	removeDestination(trainHandle, routeIndex) {
		const train = this.trains[trainHandle];

		train.route.splice(routeIndex, 1);

		if (!train.route.length) {
			train.routeIndex = 0;
		} else {
			// Decrement current index if after the removed index:
			if (train.routeIndex > routeIndex) {
				train.routeIndex -= 1;
			}

			// Wrap around if overflow:
			train.routeIndex %= train.route.length;
		}
	}

	dumpState() {
		console.log('Primary state:');
		console.log('Vertices', this.graph.vertices);
		console.log('Edges', this.graph.edges);
		console.log('Trains', this.trains);
		console.log('Industries', this.industries);
		console.log('Stations', this.stations);

		console.log('Derived state:');
		console.log('Edge lengths', this.edgeLengths);
		console.log('Edge reservations', this.edgeReservations);
	}

	getRenderState() {
		// Since the returned state object has state derived from the primary
		// state, it's very important that we don't return a reference to the
		// internal graph as between frames it may contain edges that don't
		// yet have calculated edgeCurves etc. The point is, the render state
		// must be internally consistent, and we cannot guarantee it is if we
		// don't create an immutable clone of the whole thing.
		return clone({
			buildState: this.buildState,
			carriageTypes: this.carriageTypes,
			graph: {
				edges: this.graph.edges,
				vertices: this.graph.vertices
			},
			trains: this.trains.map((train, handle) => {
				return {
					combinedCapacity: this.getCombinedCapacity(handle),
					combinedItems: this.getCombinedItems(handle),
					combinedTractiveForce: this.getCombinedTractiveForce(handle),
					combinedMass: this.getCombinedMass(handle),
					stopDistance: this.getStopDistance(handle),
					reservedDistance: this.getReservedDistance(handle),
					reservedEdges: this.getReservedEdges(handle),
					remainingReservedDistance: this.getRemainingReservedDistance(handle),
					...train
				};
			}),
			industries: this.industries,
			stations: this.stations,

			// Derived state used for rendering:
			vertexEdgeCount: this.graph.vertices.map((vertex, handle) => {
				return this.graph.getEdgeCount(handle);
			}),
			edgeCurves: this.graph.edges.map((edge, handle) => {
				return this.getEdgeCurve(handle);
			}),
			edgeIsTaken: this.graph.edges.map((edge, handle) => {
				return this.isEdgeTaken(handle);
			}),
			trainCarriagePoints: this.trains.map((train, handle) => {
				return this.getCarriagePoints(handle);
			})
		});
	}

	getEdgeAtPosition(position) {
		let result = null;

		this.graph.edges.some((edge, handle) => {
			const curve = this.getEdgeCurve(handle);

			if (Bezier.distance(curve, position) < 10) {
				result = handle;
				return true;
			}
		});

		return result;
	}

	getIndustryAtPosition(position) {
		let result = null;

		this.industries.some((industry, handle) => {
			if (Vector.distance(industry.position, position) < industry.radius) {
				result = handle;
				return true;
			}
		});

		return result;
	}

	getTrainAtPosition(position) {
		let result = null;

		this.trains.some((train, handle) => {
			const points = this.getCarriagePoints(handle);

			return points.some((point) => {
				if (Vector.distance(point, position) < 15) {
					result = handle;
					return true;
				}
			});
		});

		return result;
	}

	getVertexAtPosition(position) {
		let result = null;

		this.graph.vertices.some((vertex, handle) => {
			const distance = Vector.distance(vertex.position, position);

			if (distance < 10) {
				result = handle;
				return true;
			}

			return false;
		});

		return result;
	}

	getControlPointAtPosition(position) {
		let result = null;

		this.graph.vertices.some((vertex, handle) => {
			const vertDistance = Vector.distance(vertex.position, position);
			const controlInDistance = Vector.distance(vertex.controlIn, position);
			const controlOutDistance = Vector.distance(vertex.controlOut, position);

			if (controlInDistance < 10) {
				result = {
					vertex: handle,
					controlPoint: 0
				};

				return true;
			} else if (controlOutDistance < 10) {
				result = {
					vertex: handle,
					controlPoint: 2
				};

				return true;
			} else if (vertDistance < 10) {
				result = {
					vertex: handle,
					controlPoint: 1
				};

				return true;
			}

			return false;
		});

		return result;
	}

	placeStation(position) {
		const edge = this.getEdgeAtPosition(position);

		if (edge !== null) {
			if (!this.stations[edge]) {
				this.stations[edge] = {
					items: 0
				};
			}
		}

		return edge;
	}

	placeTrain(position) {
		const vertex = this.getVertexAtPosition(position);
		const carriages = [];

		let handle = null;

		// Add a locomotive:
		carriages.push({
			type: 0,
			items: 0
		});

		// Add some passenger carriages:
		for (let i = 0; i < 6; i += 1) {
			carriages.push({
				type: 1,
				items: 0
			});
		}

		const train = {
			carriages: carriages,

			reservedEdges: [],
			currentEdgeIndex: 0,

			progress: 1,
			speed: 0,

			state: TrainState.IDLE,
			stateTime: 0,

			route: [],
			routeIndex: 0
		};

		if (vertex !== null) {
			const placementPath = this.findPlacementPath(vertex, train.carriages);

			if (placementPath === false) {
				console.log('Unable to place train (Not enough unreserved edges).');
			} else {
				handle = this.trains.push(train) - 1;

				this.pushEdgeReservations(handle, placementPath);
				this.trains[handle].currentEdgeIndex = placementPath.length - 1;
			}
		}

		return handle;
	}

	getUnreservedInEdges(vertex, removeEdges) {
		const inEdges = this.graph.getInEdges(vertex);

		if (!removeEdges) {
			removeEdges = [];
		}

		return inEdges.filter((edge) => {
			if (this.isEdgeTaken(edge) || removeEdges.indexOf(edge) !== -1) {
				return false;
			}

			return true;
		});
	}

	getEdgeCurve(handle) {
		const edge = this.graph.edges[handle];
		const from = this.graph.vertices[edge.from];
		const to = this.graph.vertices[edge.to];

		return {
			start: from.position,
			cp1: from.controlOut,
			cp2: to.controlIn,
			end: to.position
		};
	}

	placeAlongPath(edges, carriages, startLocation) {
		const edgesReversed = edges.slice().reverse();
		const curve = this.getEdgeCurve(edgesReversed[0]);
		const remainingCarriages = [].concat(carriages);

		let location = typeof (startLocation) !== 'undefined' ? startLocation : 1;
		let position = Bezier.point(curve, location);
		const points = [position];

		// TODO: Allow travelling a set distance along the path before finding next
		//       intersection. This can probably be used to handle wheel offset for
		//       the carriages.

		edgesReversed.some((edgeHandle) => {
			do {
				const carriageType = this.carriageTypes[remainingCarriages[0].type];
				const intersection = this.findPreviousCircleIntersection(edgeHandle, location, {
					position: position,
					radius: carriageType.length
				});

				// Couldn't find an intersection on this edge, move to the next
				// one, starting at location 1 as we're moving backwards though
				// the edges:
				if (intersection === false) {
					location = 1;
					return false;
				}

				position = intersection.position;
				location = intersection.location;

				points.push(position);
				remainingCarriages.shift();
			} while (remainingCarriages.length);

			return !remainingCarriages.length; // Loop through edges until we've placed all carriages:
		});

		return points;
	}

	findPreviousCircleIntersection(edgeHandle, startLocation, circle) {
		const curve = this.getEdgeCurve(edgeHandle);

		let intersection = false;

		Intersect.bezierCirclePoints(curve, circle, 0.1, 0, startLocation).some((candidate) => {
			if (candidate.location < startLocation) {
				intersection = candidate;
			}
		});

		return intersection;
	}

	findPlacementPath(vertex, carriages) {
		let inEdges = this.getUnreservedInEdges(vertex);
		let path = [];
		let trainPlaced = false;
		let points = false;

		while (inEdges.length && !trainPlaced) {
			const edge = inEdges[0];

			path = [edge].concat(path); // Add the edge to front as we're moving backwards.
			points = this.placeAlongPath(path, carriages);

			if (points.length === (carriages.length + 1)) {
				trainPlaced = true;
			} else {
				// Add another edge:
				vertex = this.graph.edges[edge].from;
				inEdges = this.getUnreservedInEdges(vertex, path);
			}
		}

		if (!trainPlaced) {
			return false;
		}

		return path;
	}

	isEdgeTaken(handle) {
		return (typeof (this.edgeReservations[handle]) !== 'undefined');
	}

	pushEdgeReservation(train, edge) {
		// Preconditions:
		// - Edge must be unreserved.
		// - Edge must be connected to the last currently reserved edge of the train.

		this.trains[train].reservedEdges.push(edge);
		this.edgeReservations[edge] = train;
	}

	pushEdgeReservations(train, edges) {
		edges.forEach((edge) => {
			this.trains[train].reservedEdges.push(edge);
			this.edgeReservations[edge] = train;
		});
	}

	popEdgeReservation(trainHandle) {
		// Preconditions:
		// - Train must have more than one reserved edge.
		// - Train must have progress == 1
		const train = this.trains[trainHandle];

		delete this.edgeReservations[train.reservedEdges[0]];
		train.reservedEdges.shift();
		train.currentEdgeIndex -= 1;
	}

	attemptReserveEdge(handle, passStation) {
		const train = this.trains[handle];
		const lastEdgeHandle = train.reservedEdges[train.reservedEdges.length - 1];
		const lastEdge = this.graph.edges[lastEdgeHandle]; // Trains should always have at least one reserved edge.

		let edge = null;

		if (this.stations[lastEdgeHandle] && !passStation) {
			return false;
		}

		if (train.route.length) {
			const targetHandle = train.route[train.routeIndex];
			const target = this.graph.edges[targetHandle];

			if (lastEdge.to === target.from) {
				// The last reserved edge is connected directly to the
				// target edge. No need to search for it:
				if (!this.isEdgeTaken(targetHandle)) {
					edge = targetHandle;
				}
			} else {
				// Find the shortest path to the target edge and attempt
				// to reserve the first one:
				const path = this.graph.shortestPath(lastEdge.to, target.from);
				if (path.length) {
					edge = path[0];
				}
			}

			if (edge !== null && this.isEdgeTaken(edge)) {
				edge = null;
			}

			// If we reached the route target, skip to the next target:
			if (edge !== null && edge === targetHandle) {
				train.routeIndex = (train.routeIndex + 1) % train.route.length;
				// console.log('Changing route index', train.routeIndex);
			}
		} else {
			const outEdges = this.graph.getOutEdges(lastEdge.to);

			outEdges.some((candidate) => {
				if (!this.isEdgeTaken(candidate)) {
					edge = candidate;
					return true;
				}
				return false;
			});
		}

		if (edge !== null) {
			this.pushEdgeReservation(handle, edge);
		}
	}

	unreserveFreeEdges(handle) {
		// Attempt to release reservation for any edges the entire
		// train has now travelled past:
		const train = this.trains[handle];
		const edgeHandle = train.reservedEdges[train.currentEdgeIndex];
		const curve = this.getEdgeCurve(edgeHandle);
		const totalDistance = this.getEdgeDistance(edgeHandle);
		const distance = totalDistance * train.progress;
		const location = Scalar.clamp(0, 1, Bezier.locationAtDistance(curve, 0, distance));

		let travelledEdgesFirstRemoved = train.reservedEdges.slice(1, train.currentEdgeIndex + 1);

		while (
			travelledEdgesFirstRemoved.length &&
			this.placeAlongPath(travelledEdgesFirstRemoved, train.carriages, location).length === (train.carriages.length + 1)
		) {
			this.popEdgeReservation(handle);
			travelledEdgesFirstRemoved = train.reservedEdges.slice(1, train.currentEdgeIndex + 1);
		}
	}

	getCombinedAcceleration(handle) {
		return this.getCombinedTractiveForce(handle) / this.getCombinedMass(handle);
	}

	getCombinedCapacity(handle) {
		const train = this.trains[handle];

		return train.carriages.reduce((capacity, carriage) => {
			const type = this.carriageTypes[carriage.type];

			return capacity + type.capacity;
		}, 0);
	}

	getCombinedMass(handle) {
		const train = this.trains[handle];

		return train.carriages.reduce((mass, carriage) => {
			const type = this.carriageTypes[carriage.type];

			return mass + type.mass + (type.itemMass * carriage.items);
		}, 0);
	}

	getCombinedItems(handle) {
		const train = this.trains[handle];

		return train.carriages.reduce((items, carriage) => {
			return items + carriage.items;
		}, 0);
	}

	getCombinedTractiveForce(handle) {
		const train = this.trains[handle];

		return train.carriages.reduce((tractiveForce, carriage) => {
			const type = this.carriageTypes[carriage.type];

			return tractiveForce + type.tractiveForce;
		}, 0);
	}

	getMaxSpeed(handle) {
		const train = this.trains[handle];

		return train.carriages.reduce((max, carriage) => {
			const type = this.carriageTypes[carriage.type];

			return Math.min(max, type.maxSpeed);
		}, Infinity);
	}

	getReservedEdges(handle) {
		const train = this.trains[handle];

		return train.reservedEdges;
	}

	getEdgeDistance(handle) {
		return this.edgeLengths[handle];
	}

	getReservedDistance(handle) {
		const edges = this.getReservedEdges(handle);

		return edges.reduce((distance, edgeHandle) => {
			return distance + this.getEdgeDistance(edgeHandle);
		}, 0);
	}

	getRemainingReservedDistance(handle) {
		const train = this.trains[handle];
		const reservedDistance = this.getReservedDistance(handle);
		const travelledEdges = train.reservedEdges.slice(0, train.currentEdgeIndex);

		let travelledDistance = travelledEdges.reduce((distance, edge) => {
			return distance + this.getEdgeDistance(edge);
		}, 0);

		travelledDistance += train.progress * this.getProgressScale(handle);

		return reservedDistance - travelledDistance;
	}

	getStopDistance(handle) {
		const train = this.trains[handle];
		const acceleration = this.getCombinedAcceleration(handle);
		const stopTime = (-train.speed) / (-acceleration);

		return (train.speed * stopTime) + (-acceleration * stopTime * stopTime * 0.5);
	}

	getProgressScale(handle) {
		const train = this.trains[handle];

		return this.getEdgeDistance(train.reservedEdges[train.currentEdgeIndex]);
	}

	getIndustriesIntersectingEdge(handle) {
		const curve = this.getEdgeCurve(handle);

		return this.industries.filter((industry) => {
			if (Bezier.distance(curve, industry.position) < industry.radius) {
				return true;
			}

			return false;
		});
	}

	fillCarriages(carriages, items) {
		carriages.forEach((carriage) => {
			const carriageType = this.carriageTypes[carriage.type];
			const remainingSpace = carriageType.capacity - carriage.items;
			const itemsToLoad = Math.min(remainingSpace, items);

			carriage.items += itemsToLoad;
			items -= itemsToLoad;
		});

		return items;
	}

	updateLoadingTrain(handle) {
		const train = this.trains[handle];
		const currentEdge = train.reservedEdges[train.currentEdgeIndex];
		const industries = this.getIndustriesIntersectingEdge(currentEdge);

		// Load items to fill capacity:
		const doneLoading = industries.some((industry) => {
			industry.items = this.fillCarriages(train.carriages, industry.items);

			if (industry.items > 0) {
				return true;
			}
		});

		// Start driving again:
		if ((industries.length < 1 || doneLoading) && train.stateTime > 10) {
			train.state = TrainState.RUN;
			train.stateTime = 0;

			// The run state will never advance past a station, so
			// do that here:
			this.attemptReserveEdge(handle, true);
		}
	}

	updateUnloadingTrain(handle) {
		const train = this.trains[handle];

		if (this.getCombinedItems(handle) > 0) {
			const currentEdge = train.reservedEdges[train.currentEdgeIndex];
			const industries = this.getIndustriesIntersectingEdge(currentEdge);

			if (industries.length) {
				// If there is an industry nearby, simply unload all cargo
				// into the void. Need to separate producers/consumers later:
				train.carriages.forEach(function (carriage) {
					carriage.items = 0;
				});
			}
		}

		// Wait a bit for stating to load:
		if (train.stateTime > 5) {
			// Load more items:
			train.state = TrainState.LOAD;
			train.stateTime = 0;
		}
	}

	updateRunningTrain(handle, dt, stop) {
		const train = this.trains[handle];
		const acceleration = this.getCombinedAcceleration(handle);
		const speedLimit = this.getMaxSpeed(handle);
		const stopDistance = this.getStopDistance(handle);
		const progressScale = this.getProgressScale(handle);

		let remainingDistance = 0;
		let edgesAvailable = true;

		// Find out how far the train has moved since last update:
		train.progress += (train.speed / progressScale * dt);

		// Find out how much reserved space we have left to drive on and try to
		// reserve more if we're about to run out:
		remainingDistance = this.getRemainingReservedDistance(handle);

		while (remainingDistance < stopDistance && edgesAvailable) {
			edgesAvailable = this.attemptReserveEdge(handle, false); // Never run past a station
			remainingDistance = this.getRemainingReservedDistance(handle);
		}

		// Accelerate while we have time to stop, otherwise hit the brakes.
		// Note that trains also keep moving forward once speed has decreased
		// to a certain level. This is to make sure we actually hit the end
		// of the reserved space instead of stopping short:
		if (!stop && (remainingDistance > stopDistance || train.speed < 0.1)) {
			train.speed += acceleration * dt;
		} else {
			train.speed -= acceleration * dt;
		}

		// Clamp train speed while wind resistance is not simulated:
		if (train.speed > speedLimit) {
			train.speed = speedLimit;
		}

		// Handle the train driving off the end of the current edge:
		if (train.progress > 1) {
			if (train.reservedEdges.length > (train.currentEdgeIndex + 1)) {
				// The train as gone past the edge of its current edge. Start
				// driving along the next reserved one:
				// this.popEdgeReservation(handle);
				train.currentEdgeIndex += 1;
				train.progress = 0;
			} else {
				// Since trains will keep edging forwards when reacing the end
				// of their reserved space, clamp their movement to the end of
				// the current edge:
				train.progress = 1;
				train.speed = 0;

				// The train has reached a station, start loading:
				if (this.stations[train.reservedEdges[train.currentEdgeIndex]]) {
					this.unreserveFreeEdges(handle);
					train.state = TrainState.UNLOAD;
					train.stateTime = 0;
				}
			}
		}

		if (train.state === TrainState.RUN && train.stateTime > 5) {
			this.unreserveFreeEdges(handle);
			train.stateTime = 0;
		}

		if (stop && train.speed <= 0) {
			this.unreserveFreeEdges(handle);
			train.speed = 0;
			train.state = TrainState.IDLE;
			train.stateTime = 0;
		}
	}

	update(dt) {
		// Simulate industries:
		this.industries.forEach((industry) => {
			const itemTime = 1 / industry.productivity;
			const itemCount = Math.floor(industry.productionTime / itemTime);

			industry.items = Math.min(industry.items + itemCount, industry.maxItems);
			industry.productionTime -= itemTime * itemCount;

			industry.productionTime += dt;
		});

		// Simulate trains:
		this.trains.forEach((train, handle) => {
			train.stateTime += dt;

			switch (train.state) {
			case TrainState.IDLE:
				// Do nothing.
				break;
			case TrainState.RUN:
				this.updateRunningTrain(handle, dt, false);
				break;
			case TrainState.LOAD:
				this.updateLoadingTrain(handle, dt);
				break;
			case TrainState.UNLOAD:
				this.updateUnloadingTrain(handle, dt);
				break;
			case TrainState.STOP:
				this.updateRunningTrain(handle, dt, true);
				break;
			}
		});
	}

	startBuild(position) {
		const vertex = this.getVertexAtPosition(position);

		if (vertex !== null) {
			this.buildState.startVertex = vertex;
			this.buildState.startPosition = this.graph.vertices[vertex].position;
		} else {
			this.buildState.startVertex = null;
			this.buildState.startPosition = position;
		}
	}

	updateBuild(position) {
		if (this.buildState.startPosition) {
			const vertex = this.getVertexAtPosition(position);

			if (vertex !== null) {
				this.buildState.endVertex = vertex;
				this.buildState.endPosition = this.graph.vertices[vertex].position;
			} else {
				this.buildState.endVertex = null;
				this.buildState.endPosition = position;
			}
		}
	}

	cancelBuild() {
		this.buildState.startVertex = null;
		this.buildState.startPosition = null;
		this.buildState.endVertex = null;
		this.buildState.endPosition = null;
	}

	completeBuild(position) {
		this.updateBuild(position);

		if (this.buildState.endPosition) {
			const normal = Vector.normalize(Vector.subtract(this.buildState.endPosition, this.buildState.startPosition));

			let from = null;
			let to = null;

			if (this.buildState.startVertex !== null) {
				console.log('Starting at existing vertex', this.buildState.startVertex);
				from = this.buildState.startVertex;
			} else {
				from = this.graph.addVertex({
					position: this.buildState.startPosition,
					controlIn: Vector.subtract(this.buildState.startPosition, Vector.multiply(normal, 30)),
					controlOut: Vector.add(this.buildState.startPosition, Vector.multiply(normal, 30))
				});
			}
			console.log('From vertex', this.graph.vertices[from]);

			if (this.buildState.endVertex !== null) {
				console.log('Ending at existing vertex', this.buildState.endVertex);
				to = this.buildState.endVertex;
			} else {
				to = this.graph.addVertex({
					position: this.buildState.endPosition,
					controlIn: Vector.subtract(this.buildState.endPosition, Vector.multiply(normal, 30)),
					controlOut: Vector.add(this.buildState.endPosition, Vector.multiply(normal, 30))
				});
			}
			console.log('To vertex', this.graph.vertices[to]);

			const edge = this.graph.addEdge({
				from: from,
				to: to
			});

			this.recalculateEdgeWeight(edge);

			console.log('Created edge', from, to, edge);
		}

		this.cancelBuild();
	}

	startMove(position) {
		this.moveState = this.getControlPointAtPosition(position);
		this.updateMove(position);
	}

	updateMove(position) {
		if (this.moveState) {
			const vertex = this.graph.vertices[this.moveState.vertex];

			if (this.moveState.controlPoint === 1) {
				const offset = Vector.subtract(position, vertex.position);

				vertex.position = Vector.add(vertex.position, offset);
				vertex.controlIn = Vector.add(vertex.controlIn, offset);
				vertex.controlOut = Vector.add(vertex.controlOut, offset);
			} else if (this.moveState.controlPoint === 0) {
				const offset = Vector.subtract(position, vertex.controlIn);

				vertex.controlIn = Vector.add(vertex.controlIn, offset);
				vertex.controlOut = Vector.subtract(vertex.controlOut, offset);
			} else if (this.moveState.controlPoint === 2) {
				const offset = Vector.subtract(position, vertex.controlOut);

				vertex.controlIn = Vector.subtract(vertex.controlIn, offset);
				vertex.controlOut = Vector.add(vertex.controlOut, offset);
			}

			this.recalculateEdgeWeightForVertex(this.moveState.vertex);
		}
	}

	cancelMove() {
		this.moveState = null;
	}

	completeMove(position) {
		this.updateMove(position);
		this.moveState = null;
	}

	placeIndustry(config) {
		const industry = {
			position: {x: 0, y: 0},
			radius: 40,
			productivity: 6, // items per second.
			productionTime: 0, // Time into production of an item
			items: 0,
			maxItems: 120,
			...config
		};

		return this.industries.push(industry) - 1;
	}

	deleteEdge(handle) {
		const edge = this.graph.edges[handle];

		// Simply don't allow deleting a reserved edge as we'd have
		// to do something smart with the train that is currently
		// using it.
		if (this.isEdgeTaken(handle)) {
			return false;
		}

		// If there is a station associated with the edge, remove that:
		if (this.stations[handle]) {
			delete this.stations[handle];
		}

		// Delete the edge from the graph, and delete any vertices
		// that became orphans as a result of the edge being deleted.
		// By doing vertex cleanup here, we don't actually need to
		// provide the ability to delete vertices as they're deleted
		// automatically when they're no longer in use.
		this.graph.deleteEdge(handle);
		if (edge.from && this.graph.getVertexEdges(edge.from).length === 0) {
			this.graph.deleteVertex(edge.from);
		}
		if (edge.to && this.graph.getVertexEdges(edge.to).length === 0) {
			this.graph.deleteVertex(edge.to);
		}

		// Finally, delete any derived state refering to the now
		// deleted edge:
		delete this.edgeLengths[handle];
		delete this.edgeReservations[handle];

		return true;
	}

	recalculateEdgeWeightForVertex(vertexHandle) {
		this.graph.edges.forEach((edge, edgeHandle) => {
			if (edge.from === vertexHandle || edge.to === vertexHandle) {
				this.recalculateEdgeWeight(edgeHandle);
			}
		});
	}

	recalculateEdgeWeight(handle) {
		const curve = this.getEdgeCurve(handle);
		const length = Bezier.length(curve);

		this.edgeLengths[handle] = length;
		this.graph.edges[handle].weight = length;
	}

	getCarriagePoints(handle) {
		const train = this.trains[handle];
		const edgeHandle = train.reservedEdges[train.currentEdgeIndex];
		const curve = this.getEdgeCurve(edgeHandle);
		const totalDistance = this.getEdgeDistance(edgeHandle);
		const distance = totalDistance * train.progress;
		const location = Scalar.clamp(0, 1, Bezier.locationAtDistance(curve, 0, distance));
		const travelledEdges = train.reservedEdges.slice(0, train.currentEdgeIndex + 1);

		return this.placeAlongPath(travelledEdges, train.carriages, location);
	}

	getItemAt(position) {
		let result = this.getTrainAtPosition(position);
		if (result !== null) {
			return {
				type: ItemType.TRAIN,
				handle: result
			};
		}

		result = this.getIndustryAtPosition(position);
		if (result !== null) {
			return {
				type: ItemType.INDUSTRY,
				handle: result
			};
		}

		result = this.getEdgeAtPosition(position);
		if (result !== null) {
			return {
				type: ItemType.EDGE,
				handle: result
			};
		}

		return null;
	}

	getState() {
		return {
			vertices: this.graph.vertices,
			edges: this.graph.edges,
			trains: this.trains,
			industries: this.industries,
			stations: this.stations,
			edgeLengths: this.edgeLengths,
			edgeReservations: this.edgeReservations
		};
	}

	setState(state) {
		this.graph.vertices = state.vertices;
		this.graph.edges = state.edges;
		this.trains = state.trains;
		this.industries = state.industries;
		this.stations = state.stations;
		this.edgeLengths = state.edgeLengths;
		this.edgeReservations = state.edgeReservations;

		// Backwards compatibility:
		this.trains.forEach((train) => {
			if (!train.route) {
				train.route = [];
				train.routeIndex = 0;
			}
		});
	}

	startTrain(handle) {
		const train = this.trains[handle];

		train.state = TrainState.RUN;
		train.stateTime = 0;
	}

	stopTrain(handle) {
		const train = this.trains[handle];

		train.state = TrainState.STOP;
		train.stateTime = 0;
	}
}

export default Simulation;

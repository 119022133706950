import PropTypes from 'prop-types';
import React, {Component} from 'react';
import TrainState from '../../TrainState';

const stateMap = {
	[TrainState.IDLE]: 'Idling',
	[TrainState.RUN]: 'Running',
	[TrainState.STOP]: 'Stopping',
	[TrainState.LOAD]: 'Loading',
	[TrainState.UNLOAD]: 'Unloading'
};

class TrainStatisticsComponent extends Component {
	static propTypes = {
		handle: PropTypes.number.isRequired,
		renderState: PropTypes.object.isRequired
	};

	render() {
		const handle = this.props.handle;
		const train = this.props.renderState.trains[handle];

		return (
			<dl>
				<dt>State</dt>
				<dd>{stateMap[train.state]} (for {train.stateTime.toFixed(2)}s)</dd>

				<dt>Cargo</dt>
				<dd>{train.combinedItems} / {train.combinedCapacity} items</dd>

				<dt>Tractive force</dt>
				<dd>{train.combinedTractiveForce} N</dd>

				<dt>Mass</dt>
				<dd>{train.combinedMass} kg</dd>

				<dt>Velocity</dt>
				<dd>{(train.speed * 3.6).toFixed(2)} km/h</dd>

				<dt>Braking distance</dt>
				<dd>{train.stopDistance.toFixed(2)} m</dd>

				<dt>Current edge</dt>
				<dd>{train.reservedEdges[train.currentEdgeIndex]}</dd>

				<dt>Reserved edges</dt>
				<dd>{train.reservedEdges.join(', ')}</dd>

				<dt>Reserved distance</dt>
				<dd>{train.reservedDistance.toFixed(2)} m</dd>

				<dt>Remaining reserved distance</dt>
				<dd>{train.remainingReservedDistance.toFixed(2)} m</dd>
			</dl>
		);
	}
}

export default TrainStatisticsComponent;

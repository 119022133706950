
class EventHandler {
	constructor() {
		this.listeners = {};
	}

	destruct() {
		this.listeners = {};
	}

	disconnect(event, listener) {
		if (!this.listeners[event]) {
			return;
		}

		const index = this.listeners[event].indexOf(listener);

		if (index !== -1) {
			this.listeners[event] = this.listeners[event].slice(index, index + 1);
		}
	}

	triggerEvent(event, ...parameters) {
		if (!this.listeners[event]) {
			return Promise.resolve([]);
		}

		const results = this.listeners[event].map((callback) => {
			return Promise.resolve(callback(...parameters));
		});

		return Promise.all(results);
	}

	one(event, callback) {
		let connection = null;

		const wrapper = (...parameters) => {
			connection.disconnect();
			return callback(...parameters);
		};

		connection = this.on(event, wrapper);

		return connection;
	}

	on(eventOrObject, callback) {
		if (typeof eventOrObject === 'string') {
			const event = eventOrObject;

			if (!this.listeners[event]) {
				this.listeners[event] = [];
			}

			this.listeners[event].push(callback);

			return {
				disconnect: () => this.disconnect(event, callback)
			};
		}
		const object = eventOrObject;
		const connections = [];

		for (const event in object) {
			if (object.hasOwnProperty(event)) {
				connections.push(this.on(event, object[event]));
			}
		}

		return {
			disconnect: () => {
				connections.forEach((connection) => connection.disconnect());
			}
		};
	}
}

export default EventHandler;

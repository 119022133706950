import $ from 'jquery';
import InterfaceContainer from '../logistics/ui/components/InterfaceContainer';
import popper from 'popper.js';
import React from 'react';
import {render} from 'react-dom';
import {whenDomReady} from '../engine/dom';
import '../../scss/main.scss';

window.jQuery = $;
window.$ = $;
window.Popper = popper;
require('bootstrap');

whenDomReady(() => {
	const container = document.querySelector('.react-render-slot');

	render(
		<InterfaceContainer />,
		container
	);
});

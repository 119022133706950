/**
 * Utility functions for working with 2D vectors.
 *
 * A "vector" is any object on the form:
 *
 *     {
 *         x: <Number>,
 *         y: <Number>
 *     }
 */

const add = (lhs, rhs) => {
	return {
		x: lhs.x + rhs.x,
		y: lhs.y + rhs.y
	};
};

const subtract = (lhs, rhs) => {
	return {
		x: lhs.x - rhs.x,
		y: lhs.y - rhs.y
	};
};

const distance = (from, to) => {
	return length(subtract(from, to));
};

const divide = (vec, scalar) => {
	return {
		x: vec.x / scalar,
		y: vec.y / scalar
	};
};

const dot = (vec1, vec2) => {
	return (vec1.x * vec2.x) + (vec1.y * vec2.y);
};

const invert = (vec) => {
	return {
		x: -vec.x,
		y: -vec.y
	};
};

const length = (vec) => {
	const squareSum = (vec.x * vec.x) + (vec.y * vec.y);

	return Math.sqrt(squareSum);
};

const lerp = (from, to, alpha) => {
	return add(from, multiply(subtract(to, from), alpha));
};

const multiply = (vec, scalar) => {
	return {
		x: vec.x * scalar,
		y: vec.y * scalar
	};
};

const normalize = (vec) => {
	return divide(vec, length(vec));
};

const rotate = (vec, radians) => {
	const cos = Math.cos(radians);
	const sin = Math.sin(radians);

	return {
		x: (vec.x * cos) - (vec.y * sin),
		y: (vec.x * sin) + (vec.y * cos)
	};
};

export default {
	add,
	subtract,
	distance,
	divide,
	dot,
	invert,
	length,
	lerp,
	multiply,
	normalize,
	rotate
};

import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';

class StateListComponent extends Component {
	static propTypes = {
		onStateAddCurrent: PropTypes.func.isRequired,
		onStatesClear: PropTypes.func.isRequired,
		onStateLoad: PropTypes.func.isRequired,
		onStateRemove: PropTypes.func.isRequired,
		states: PropTypes.array.isRequired
	};

	render() {
		return (
			<Fragment>
				<h3>States</h3>
				<table className="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{this.props.states.map((state, index) => {
							return (
								<tr key={index}>
									<td className="align-middle">State {index}</td>
									<td>
										<button
											className="btn btn-link"
											onClick={() => this.props.onStateLoad(index)}
										>
											Load
										</button>
										<button
											className="btn btn-link"
											onClick={() => this.props.onStateRemove(index)}
										>
											Remove
										</button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<p>
					<button
						className="btn btn-secondary"
						onClick={this.props.onStateAddCurrent}
					>
						<i className="icon-plus-sign"></i> Add current state
					</button>
					&nbsp;
					<button
						className="btn btn-link"
						onClick={this.props.onStatesClear}
					>
						Clear states
					</button>
				</p>
			</Fragment>
		);
	}
}

export default StateListComponent;

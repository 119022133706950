import ControllerMode from '../ControllerMode';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

class Tools extends Component {
	static propTypes = {
		enableDeletion: PropTypes.bool.isRequired,
		industries: PropTypes.array.isRequired,
		mode: PropTypes.number.isRequired,
		onDeleteSelection: PropTypes.func.isRequired,
		onSelectIndustry: PropTypes.func.isRequired,
		onSelectTrain: PropTypes.func.isRequired,
		onTimeMultiplierDecrease: PropTypes.func.isRequired,
		onTimeMultiplierIncrease: PropTypes.func.isRequired,
		onSetMode: PropTypes.func.isRequired,
		trains: PropTypes.array.isRequired
	};

	createModeButton(mode, text) {
		return (
			<button
				className={`btn btn-secondary ${this.props.mode === mode ? 'active' : ''}`}
				onClick={() => this.props.onSetMode(mode)}
			>
				{text}
			</button>
		);
	}

	createIndustryDropdown(industries) {
		return (
			<div className="btn-group">
				<button
					className={`btn btn-secondary dropdown-toggle ${industries.length ? '' : 'disabled'}`}
					data-toggle="dropdown"
				>
					Industries
				</button>
				<div className="dropdown-menu">
					{industries.map((industry, handle) => {
						return (
							<button
								onClick={() => this.props.onSelectIndustry(handle)}
								className="dropdown-item"
								key={handle}
							>
								Industry {handle}
							</button>
						);
					})}
				</div>
			</div>
		);
	}

	createTrainDropdown(trains) {
		return (
			<div className="btn-group">
				<button
					className={`btn btn-secondary dropdown-toggle ${trains.length ? '' : 'disabled'}`}
					data-toggle="dropdown"
				>
					Trains
				</button>
				<div className="dropdown-menu">
					{trains.map((train, handle) => {
						return (
							<button
								onClick={() => this.props.onSelectTrain(handle)}
								className="dropdown-item"
								key={handle}
							>
								Train {handle}
							</button>
						);
					})}
				</div>
			</div>
		);
	}

	render() {
		return (
			<div className="text-light bg-dark d-flex flex-wrap justify-content-between p-1">
				<div className="m-1 btn-group">
					{this.createModeButton(ControllerMode.SELECT_ITEM, 'Select')}
					<button
						className="btn btn-secondary"
						disabled={!this.props.enableDeletion}
						onClick={() => this.props.onDeleteSelection()}
					>
						Delete
					</button>
				</div>
				<div className="m-1 btn-group">
					{this.createModeButton(ControllerMode.CREATE_EDGE, 'Create edge')}
					{this.createModeButton(ControllerMode.MOVE_VERTEX, 'Move vertex')}
				</div>
				<div className="m-1 btn-group">
					{this.createModeButton(ControllerMode.PLACE_TRAIN, 'Place train')}
					{this.createModeButton(ControllerMode.PLACE_INDUSTRY, 'Place industry')}
					{this.createModeButton(ControllerMode.PLACE_STATION, 'Place station')}
				</div>
				<div className="m-1 btn-group">
					{this.createTrainDropdown(this.props.trains)}
					{this.createIndustryDropdown(this.props.industries)}
				</div>
				<div className="m-1 ml-auto d-flex align-items-center">
					<div className="mr-2">
						Time multiplier:
					</div>
					<div className="btn-group">
						<button
							className="btn btn-secondary"
							onClick={() => this.props.onTimeMultiplierIncrease()}
						>
							<i className="fa fa-plus"></i>
						</button>
						<button
							className="btn btn-secondary"
							onClick={() => this.props.onTimeMultiplierDecrease()}
						>
							<i className="fa fa-minus"></i>
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default Tools;

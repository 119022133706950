import Vector from './vector.js';

const fromIntersectingPoints = (point1, point2) => {
	return {
		center: Vector.lerp(point1, point2, 0.5),
		radius: Vector.distance(point1, point2) * 0.5
	};
};

export default {
	fromIntersectingPoints
};

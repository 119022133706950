import AABox from './math/aabox';
import Circle from './math/circle';
import Vector from './math/vector';
import EventHandler from './EventHandler';

const GestureType = {
	TAP: 0,
	HOLD: 1,
	PAN: 2,
	PINCH: 3
};

class HoldGesture {
	constructor(config) {
		this.ended = false;
		this.promoted = false;
		this.eventHandler = new EventHandler();

		this.detector = config.detector;
		this.startTime = config.time;
		this.startCoord = config.coord;
		this.coord = config.coord;
		this.id = config.id;
	}

	on(event, callback) {
		this.eventHandler.on(event, callback);
	}

	getId() {
		return this.id;
	}

	getCoord() {
		return this.coord;
	}

	getDuration() {
		return Date.now() - this.startTime;
	}

	getType() {
		return GestureType.HOLD;
	}

	isEnded() {
		return this.ended;
	}

	isPromoted() {
		return this.promoted;
	}

	// Private interface:
	end() {
		this.ended = true;
		this.eventHandler.triggerEvent('end');
	}

	promote() {
		this.promoted = true;
		this.end();
	}

	update(coords) {
		let gesture = this;

		if (coords.length === 1) {
			this.coord = coords[0];
			this.eventHandler.triggerEvent('update');

			// Promote to a 'pan' if it moves too much:
			if (Vector.distance(this.startCoord, this.coord) > this.detector.holdMaxMoveDistance) {
				gesture = new PanGesture({
					detector: this.detector,
					coord: this.coord,
					id: `pan-${this.coord.identifier}`
				});
				this.promote();
			}
		} else {
			gesture = null;
			this.end();
		}

		return gesture;
	}
}

class PanGesture {
	constructor(config) {
		this.ended = false;
		this.eventHandler = new EventHandler();
		this.detector = config.detector;

		this.startTime = Date.now();
		this.startCoord = config.coord;
		this.path = [config.coord];
		this.id = config.id;

		this.promoted = false;
	}

	on(event, callback) {
		this.eventHandler.on(event, callback);
	}

	getId() {
		return this.id;
	}

	getAABox() {
		return AABox.fromPoints(this.startCoord, this.getCoord());
	}

	getCoord() {
		return this.path[this.path.length - 1];
	}

	getDuration() {
		return Date.now() - this.startTime;
	}

	getPath() {
		return this.path;
	}

	getRelativeMovement() {
		return Vector.subtract(this.getCoord(), this.startCoord);
	}

	getType() {
		return GestureType.PAN;
	}

	isEnded() {
		return this.ended;
	}

	isPromoted() {
		return this.promoted;
	}

	// Private interface:
	end() {
		this.ended = true;
		this.eventHandler.triggerEvent('end');
	}

	promote() {
		this.promoted = true;
		this.end();
	}

	update(coords) {
		let gesture = this;

		if (coords.length === 1) {
			this.path.push(coords[0]);
			this.eventHandler.triggerEvent('update');
		} else if (coords.length === 2) {
			gesture = new PinchGesture({
				detector: this.detector,
				circle: Circle.fromIntersectingPoints(coords[0], coords[1]),
				id: `pinch-${coords[0].identifier}-${coords[1].identifier}`
			});
			this.promote();
		} else {
			gesture = null;
			this.end();
		}

		return gesture;
	}
}

class PinchGesture {
	constructor(config) {
		this.ended = false;
		this.eventHandler = new EventHandler();
		this.detector = config.detector;

		this.startTime = Date.now();
		this.startCircle = config.circle;
		this.circle = this.startCircle;
		this.id = config.id;

		this.promoted = false;
	}

	on(event, callback) {
		this.eventHandler.on(event, callback);
	}

	getId() {
		return this.id;
	}

	getDuration() {
		return Date.now() - this.startTime;
	}

	getCircle() {
		return this.circle;
	}

	getType() {
		return GestureType.PINCH;
	}

	getRelativeTranslation() {
		return Vector.subtract(this.circle.center, this.startCircle.center);
	}

	getRelativeScaleFactor() {
		return this.circle.radius / this.startCircle.radius;
	}

	getStartCircle() {
		return this.startCircle;
	}

	isEnded() {
		return this.ended;
	}

	isPromoted() {
		return this.promoted;
	}

	// Private interface:
	end() {
		this.ended = true;
		this.eventHandler.triggerEvent('end');
	}

	promote() {
		this.promoted = true;
		this.end();
	}

	update(coords) {
		let gesture = this;

		if (coords.length === 1) {
			gesture = new PanGesture({
				detector: this.detector,
				coord: coords[0],
				id: `pan-${coords[0].identifier}`
			});
			this.promote();
		} else if (coords.length === 2) {
			this.circle = Circle.fromIntersectingPoints(coords[0], coords[1]);
			this.eventHandler.triggerEvent('update');
		} else {
			gesture = null;
			this.end();
		}

		return gesture;
	}
}

class TapGesture {
	constructor(config) {
		// Member variables:
		this.detector = config.detector;
		this.ended = false;
		this.promoted = false;
		this.eventHandler = new EventHandler();

		this.startTime = Date.now();
		this.startCoord = config.coord;
		this.coord = config.coord;
		this.id = config.id;

		// Since a tap and hold gesture will not produce any touch update events
		// if the user is keeping the finger completely still, we need to actively
		// check if the tapMaxTime has elapsed while the tap gesture was active.
		setTimeout(() => {
			this.triggerHoldTimeCheck();
		}, this.detector.tapMaxTime);
	}

	on(event, callback) {
		this.eventHandler.on(event, callback);
	}

	getId() {
		return this.id;
	}

	getCoord() {
		return this.coord;
	}

	getDuration() {
		return Date.now() - this.startTime;
	}

	getType() {
		return GestureType.TAP;
	}

	isEnded() {
		return this.ended;
	}

	isPromoted() {
		return this.promoted;
	}

	// Private interface:
	triggerHoldTimeCheck() {
		// Tell the detector to trigger the update function on the currently
		// active gesture. Since no tocuhes have changed, the previous touches
		// touches will be passed along to the gesture one more time. This will
		// not update any touch positions, but it allows the gesture to do any
		// time dependent checks (tap -> hold promotion), which is what we want
		// here.
		this.detector.updateGesture();

		// If the tap gesture hasn't ended yet, the tapMaxTime hasn't yet
		// elapsed so we need to try again until it does. If the user lifts
		// the finger or the tap is promoted to another gesture (pan or hold)
		// the tap gesture is ended and this update loop will stop.
		if (!this.ended) {
			setTimeout(() => {
				this.triggerHoldTimeCheck();
			}, 10);
		}
	}

	end() {
		this.ended = true;
		this.eventHandler.triggerEvent('end');
	}

	promote() {
		this.promoted = true;
		this.end();
	}

	update(coords) {
		let gesture = this;

		if (coords.length === 1) {
			this.coord = coords[0];
			this.eventHandler.triggerEvent('update');

			// If the touch has strayed beyond the bounds of what can be
			// considered a "tap", promote the gesture to a "pan":
			if (Vector.distance(this.startCoord, this.coord) > this.detector.tapMaxMoveDistance) {
				gesture = new PanGesture({
					detector: this.detector,
					coord: this.coord,
					id: `pan-${this.coord.identifier}`
				});
				this.promote();
			} else if ((Date.now() - this.startTime) > this.detector.tapMaxTime) {
				gesture = new HoldGesture({
					detector: this.detector,
					time: this.startTime,
					coord: this.startCoord,
					id: `hold-${this.coord.identifier}`
				});
				gesture.update([this.coord]);
				this.promote();
			}
		} else if (coords.length === 2) {
			gesture = new PinchGesture({
				detector: this.detector,
				circle: Circle.fromIntersectingPoints(coords[0], coords[1]),
				id: `pinch-${coords[0].identifier}-${coords[1].identifier}`
			});
			this.promote();
		} else {
			gesture = null;
			this.end();
		}

		return gesture;
	}
}

class GestureDetector {
	constructor(element) {
		let mouseDown = false;

		// Settings:
		this.holdMaxMoveDistance = 10;
		this.tapMaxTime = 400;
		this.tapMaxMoveDistance = 10;
		this.usingTouchEvents = 'ontouchstart' in document.documentElement;

		this.element = element;
		this.eventHandler = new EventHandler();
		this.touches = {};

		// Hook up to the touch events on the element passed in:
		this.updateTouchesListener = (e) => {
			if (e.target === this.element) {
				e.preventDefault();
				e.stopPropagation();
				this.updateTouches(e.changedTouches);
			}
		};

		this.removeTouchesListener = (e) => {
			if (e.target === this.element) {
				e.preventDefault();
				e.stopPropagation();
				this.removeTouches(e.changedTouches);
			}
		};

		// Hook up to the mouse events and emulate a single finger touch event
		// when the primary mouse button is down:
		this.addMouseTouchListener = (e) => {
			if (e.target === this.element && e.button === 0 && !mouseDown) {
				mouseDown = true;
				e.preventDefault();
				e.stopPropagation();
				this.updateTouches([{
					pageX: e.pageX,
					pageY: e.pageY,
					identifier: 'mouse'
				}]);
			}
		};

		this.updateMouseTouchListener = (e) => {
			if (e.target === this.element && mouseDown) {
				e.preventDefault();
				e.stopPropagation();
				this.updateTouches([{
					pageX: e.pageX,
					pageY: e.pageY,
					identifier: 'mouse'
				}]);
			}
		};

		this.removeMouseTouchListener = (e) => {
			if (e.target === this.element && e.button === 0 && mouseDown) {
				mouseDown = false;
				e.preventDefault();
				e.stopPropagation();

				this.removeTouches([{
					pageX: e.pageX,
					pageY: e.pageY,
					identifier: 'mouse'
				}]);
			}
		};

		this.mouseWheelListener = (e) => {
			// Fake a zoom pinch by generating two touches and separating them by
			// the wheel delta movement, before removing them again:
			if (e.target === this.element && !mouseDown) {
				const delta = e.wheelDelta / 2;

				e.preventDefault();
				e.stopPropagation();

				this.updateTouches([
					{
						pageX: e.x - 240,
						pageY: e.y,
						identifier: 'mousewheel1'
					},
					{
						pageX: e.x + 240,
						pageY: e.y,
						identifier: 'mousewheel2'
					}
				]);

				this.removeTouches([
					{
						pageX: e.x - 240 - delta,
						pageY: e.y,
						identifier: 'mousewheel1'
					},
					{
						pageX: e.x + 240 + delta,
						pageY: e.y,
						identifier: 'mousewheel2'
					}
				]);
			}
		};

		if (this.usingTouchEvents) {
			this.element.addEventListener('touchstart', this.updateTouchesListener);
			this.element.addEventListener('touchmove', this.updateTouchesListener);
			this.element.addEventListener('touchend', this.removeTouchesListener);
			this.element.addEventListener('touchleave', this.removeTouchesListener);
			this.element.addEventListener('touchcancel', this.removeTouchesListener);
		} else {
			this.element.addEventListener('mousedown', this.addMouseTouchListener);
			this.element.addEventListener('mousemove', this.updateMouseTouchListener);
			this.element.addEventListener('mouseout', this.removeMouseTouchListener);
			this.element.addEventListener('mouseup', this.removeMouseTouchListener);
			this.element.addEventListener('mouseleave', this.removeMouseTouchListener);
			this.element.addEventListener('mousewheel', this.mouseWheelListener);
		}
	}

	destroy() {
		if (this.usingTouchEvents) {
			this.element.removeEventListener('touchstart', this.updateTouchesListener);
			this.element.removeEventListener('touchmove', this.updateTouchesListener);
			this.element.removeEventListener('touchend', this.removeTouchesListener);
			this.element.removeEventListener('touchleave', this.removeTouchesListener);
			this.element.removeEventListener('touchcancel', this.removeTouchesListener);
		} else {
			this.element.removeEventListener('mousedown', this.addMouseTouchListener);
			this.element.removeEventListener('mousemove', this.updateMouseTouchListener);
			this.element.removeEventListener('mouseout', this.removeMouseTouchListener);
			this.element.removeEventListener('mouseup', this.removeMouseTouchListener);
			this.element.removeEventListener('mouseleave', this.removeMouseTouchListener);
			this.element.removeEventListener('mousewheel', this.mouseWheelListener);
		}

		this.touches = {};
		if (this.eventHandler) {
			this.eventHandler.destroy();
			this.eventHandler = null;
		}

		this.element = null;
	}

	on(event, callback) {
		return this.eventHandler.on(event, callback);
	}

	removeTouches(removedTouches) {
		// Make sure we pass the last position update along to the gesture
		// before removing the touches.
		this.updateTouches(removedTouches);

		// Remove the touches and update the gesture again. This allows the
		// gesture to do any promotion that's required to handle the
		// disappearing touches:
		for (let index = 0; index < removedTouches.length; index += 1) {
			const touch = removedTouches[index];
			delete this.touches[touch.identifier];
		}

		this.updateGesture();
	}

	getTouchId() {
		let id = '';

		for (const index in this.touches) {
			if (this.touches.hasOwnProperty(index)) {
				id += `${index}-`;
			}
		}

		return id;
	}

	updateGesture() {
		const touches = [];

		let gestureStarted = false;

		// Build a simple array of touches instead of the associative array. We
		// don't need the identifier for the following stuff anyway:
		for (const touch in this.touches) {
			if (this.touches.hasOwnProperty(touch)) {
				touches.push(this.touches[touch]);
			}
		}

		// If no gesture is currently active, initialize a tap or a pinch
		// depending on the touch count. These may promote themselves to a pan
		// or hold as the touches are updated.
		if (!this.gesture) {
			if (touches.length === 1) {
				this.gesture = new TapGesture({
					detector: this,
					coord: touches[0],
					id: `tap-${touches[0].identifier}`
				});
				gestureStarted = true;
			} else if (touches.length === 2) {
				this.gesture = new PinchGesture({
					detector: this,
					circle: Circle.fromIntersectingPoints(touches[0], touches[1]),
					id: `pinch-${touches[0].identifier}-${touches[1].identifier}`
				});
				gestureStarted = true;
			}
		} else {
			// Allow the current gesture to update itself with the new touch
			// information. If it promotes itself to some other gesture, fire
			// off a signal that a new gesture was started. The gesture will
			// already have notified any listeners that it is being promoted
			// (ending).
			const newGesture = this.gesture.update(touches);
			if (newGesture !== this.gesture) {
				this.gesture = newGesture;

				// Only trigger event if the gesture actually promoted itself
				// to another gesture. (not null, which means it simply ended)
				if (this.gesture) {
					gestureStarted = true;
				}
			}
		}

		if (gestureStarted) {
			return this.eventHandler.triggerEvent(
				'startgesture',
				this.gesture
			).catch(function (error) {
				console.log(error);
			});
		}
	}

	updateTouches(changedTouches) {
		for (let index = 0; index < changedTouches.length; index += 1) {
			const touch = changedTouches[index];

			this.touches[touch.identifier] = {
				x: touch.pageX,
				y: touch.pageY,
				identifier: touch.identifier
			};
		}

		return this.updateGesture();
	}
}

const whenDomReady = (callback) => {
	if (document.readyState !== 'loading') {
		return callback();
	}

	document.addEventListener('DOMContentLoaded', callback);
};


export {
	GestureType,
	GestureDetector,
	whenDomReady
};

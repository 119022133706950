import Bezier from './bezier.js';
import Vector from './vector.js';
import Scalar from './scalar.js';

const OUTSIDE = 0;
const INSIDE = 1;
const INTERSECTS = 2;

const bezierCirclePoints = (curve, circle, increment, startLocation, endLocation) => {
	increment = typeof (increment) !== 'undefined' ? increment : 0.1;
	startLocation = typeof (startLocation) !== 'undefined' ? startLocation : 0;
	endLocation = typeof (endLocation) !== 'undefined' ? endLocation : 1;

	const intersections = [];
	const segment = {
		start: null,
		end: Bezier.point(curve, startLocation)
	};

	let incrementStart = startLocation;
	let incrementEnd = startLocation;

	while (incrementEnd < endLocation) {
		incrementStart = incrementEnd;
		incrementEnd = Math.min(incrementStart + increment, endLocation);

		segment.start = segment.end;
		segment.end = Bezier.point(curve, incrementEnd);

		segmentCirclePoints(segment, circle).forEach((alpha) => {
			intersections.push({
				location: Scalar.lerp(incrementStart, incrementEnd, alpha),
				position: Vector.lerp(segment.start, segment.end, alpha)
			});
		});
	}

	return intersections;
};

const pointCircle = (point, circle) => {
	const centerDistance = Vector.distance(circle.center, point);

	if (centerDistance < circle.radius) {
		return INSIDE;
	}

	return centerDistance === circle.radius ? INTERSECTS : OUTSIDE;
};

const segmentCirclePoints = (segment, circle) => {
	const d = Vector.subtract(segment.end, segment.start);
	const f = Vector.subtract(segment.start, circle.position);
	const a = Vector.dot(d, d);
	const b = 2 * Vector.dot(f, d);
	const c = Vector.dot(f, f) - (circle.radius * circle.radius);
	const intersections = [];

	let discriminant = (b * b) - (4 * a * c);

	// A discriminant less than zero means the segment completely
	// missed the circle;
	if (discriminant >= 0) {
		let t1 = 0;
		let t2 = 0;

		discriminant = Math.sqrt(discriminant);

		// either solution may be on or off the ray so need to test both
		// t1 is always the smaller value, because BOTH discriminant and
		// a are nonnegative.
		t1 = (-b - discriminant) / (2 * a);
		t2 = (-b + discriminant) / (2 * a);

		// 3x HIT cases:
		//          -o->             --|-->  |            |  --|->
		// Impale(t1 hit,t2 hit), Poke(t1 hit,t2>1), ExitWound(t1<0, t2 hit),

		// 3x MISS cases:
		//       ->  o                     o ->              | -> |
		// FallShort (t1>1,t2>1), Past (t1<0,t2<0), CompletelyInside(t1<0, t2>1)
		if (t1 >= 0 && t1 <= 1) {
			intersections.push(t1);
		}

		// here t1 didn't intersect so we are either started
		// inside the sphere or completely past it
		if (t2 >= 0 && t2 <= 1) {
			intersections.push(t2);
		}
	}

	return intersections;
};

export default {
	OUTSIDE,
	INSIDE,
	INTERSECTS,
	bezierCirclePoints,
	pointCircle,
	segmentCirclePoints
};

import Vector from './vector.js';
import Scalar from './scalar.js';

/**
 * Measures the minimum distance from a curve to a point.
 * @param {object} curve The curve to measure distance from.
 * @param {object} point The point to measure distance to.
 * @return {Number} The minimum distance from the curve to the point.
 */
const distance = (curve, point) => {
	const curvePoints = points(curve, 100);

	let minDistance = Infinity;

	curvePoints.forEach((curvePoint) => {
		minDistance = Math.min(minDistance, Vector.distance(curvePoint, point));
	});

	return minDistance;
};

const point = (curve, t) => {
	const u = 1 - t;
	const tt = t * t;
	const uu = u * u;
	const uuu = uu * u;
	const ttt = tt * t;

	let point = null;

	point = Vector.multiply(curve.start, uuu);
	point = Vector.add(point, Vector.multiply(curve.cp1, 3 * uu * t));
	point = Vector.add(point, Vector.multiply(curve.cp2, 3 * u * tt));
	point = Vector.add(point, Vector.multiply(curve.end, ttt));

	return point;
};

const points = (curve, count) => {
	let increment = 0;
	let t = 0;

	const points = [];

	if (count > 0) {
		increment = 1 / count;

		do {
			points.push(point(curve, t));
			t += increment;
			count -= 1;
		} while (count);
	}

	return points;
};

const locationAtDistance = (curve, t, distance, increment) => {
	let prevPosition = point(curve, t);
	let position = null;
	let lastDistance = 0;
	let accumulatedDistance = 0;
	let incrementStart = t;
	let incrementEnd = t;

	increment = increment || 0.1;

	// Break path up into line segments and accumulate their
	// length until we go past the target distance:
	while (accumulatedDistance < distance) {
		incrementStart = incrementEnd;
		incrementEnd = incrementStart + increment;

		position = point(curve, incrementEnd);
		lastDistance = Vector.distance(prevPosition, position);
		accumulatedDistance += lastDistance;

		prevPosition = position;
	}

	if (lastDistance === 0) {
		return incrementStart;
	}

	const overshoot = 1 - ((accumulatedDistance - distance) / lastDistance);

	return Scalar.lerp(incrementStart, incrementEnd, overshoot);
};

const length = (curve, increment) => {
	let location;
	let prevPosition = curve.start;
	let position = null;
	let length = 0;

	increment = increment || 0.1;
	location = increment;

	// Break path up into line segments and measure their
	// combined lenght:
	do {
		position = point(curve, location);
		length += Vector.distance(prevPosition, position);
		location += increment;
		prevPosition = position;
	}
	while (location < 1);

	// Measure to the end:
	length += Vector.distance(prevPosition, curve.end);

	return length;
};

export default {
	distance,
	length,
	locationAtDistance,
	point,
	points
};

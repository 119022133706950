import Vector from './vector.js';

/**
 * Finds the smallest circle intersecting both the min- and max-corner.
 * @param {Object} box The AABox to find the bounding circle of.
 * @return {Object} Circle object.
 */
const boundingCircle = (box) => {
	return {
		center: center(box),
		radius: diagonalLength(box) * 0.5
	};
};

/**
 * Creates an AABox from two points [x1, y1] and [x2, y2].
 * @param {Object} point1 One of the corners.
 * @param {Object} point2 Another of the corners.
 * @return {object} The AABox.
 */
const fromPoints = (point1, point2) => {
	return {
		minCorner: {
			x: Math.min(point1.x, point2.x),
			y: Math.min(point1.y, point2.y)
		},
		maxCorner: {
			x: Math.max(point1.x, point2.x),
			y: Math.max(point1.y, point2.y)
		}
	};
};

const center = (box) => {
	return Vector.lerp(box.minCorner, box.maxCorner, 0.5);
};

const diagonalLength = (box) => {
	return Vector.distance(box.maxCorner, box.minCorner);
};

export default {
	boundingCircle: boundingCircle,
	fromPoints: fromPoints,
	center: center,
	diagonalLength: diagonalLength
};

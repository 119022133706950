import Bezier from '../../../engine/math/bezier.js';
import Dimensions from 'react-dimensions';
import ItemType from '../../ItemType';
import Vector from '../../../engine/math/vector.js';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import injectSheet from 'react-jss';

const styles = {
	canvas: {
		width: '100%',
		height: '100%'
	}
};

class CanvasRendererComponent extends Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,

		selection: PropTypes.object,
		renderState: PropTypes.object.isRequired,
		scale: PropTypes.number.isRequired,
		center: PropTypes.object.isRequired,
		containerWidth: PropTypes.number.isRequired,
		containerHeight: PropTypes.number.isRequired,

		onInteractStart: PropTypes.func,
		onInteractUpdate: PropTypes.func,
		onInteractEnd: PropTypes.func,
		onInteractCancel: PropTypes.func,
		onTranslate: PropTypes.func,
		onScale: PropTypes.func
	};

	canvas = null;
	ctx = null;
	mounted = false;

	dragMainButton = false;
	dragSecondaryButton = false;
	previousCoord = null;

	coordToPosition(coord) {
		const center = this.props.center;
		const scale = this.props.scale;
		const viewportSize = {
			x: this.props.containerWidth,
			y: this.props.containerHeight
		};
		const translation = Vector.add(
			Vector.invert(
				Vector.multiply(center, scale)
			),
			Vector.divide(viewportSize, 2)
		);

		const position = Vector.subtract(coord, translation);

		return Vector.divide(position, scale);
	}

	handleMouseDown = (e) => {
		const coord = {
			x: e.offsetX,
			y: e.offsetY
		};

		e.stopPropagation();

		if (e.button === 0) {
			const position = this.coordToPosition(coord);

			this.props.onInteractStart(position);
			this.dragMainButton = true;
		} else if (e.button === 2) {
			this.dragSecondaryButton = true;
			this.previousCoord = coord;
		}
	};

	handleMouseMove = (e) => {
		const coord = {
			x: e.offsetX,
			y: e.offsetY
		};

		e.stopPropagation();

		if (this.dragMainButton) {
			const position = this.coordToPosition(coord);

			this.props.onInteractUpdate(position);
		} else if (this.dragSecondaryButton) {
			const scale = this.props.scale;
			let translation = Vector.subtract(this.previousCoord, coord);
			translation = Vector.divide(translation, scale);

			this.props.onTranslate(translation);
			this.previousCoord = coord;
		}
	};

	handleMouseOut = (e) => {
		e.stopPropagation();

		if (this.dragMainButton) {
			this.props.onInteractCancel();
		} else if (this.dragSecondaryButton) {
			this.dragSecondaryButton = false;
			this.previousCoord = null;
		}
	};

	handleMouseUp = (e) => {
		const coord = {
			x: e.offsetX,
			y: e.offsetY
		};

		e.stopPropagation();

		if (e.button === 0) {
			this.dragMainButton = false;

			const position = this.coordToPosition(coord);

			this.props.onInteractEnd(position);
		} else if (e.button === 2) {
			this.dragSecondaryButton = false;
			this.previousCoord = null;
		}
	};

	handleMouseWheel = (e) => {
		const factor = (1 + (e.wheelDelta / 100));
		const coord = {
			x: e.offsetX,
			y: e.offsetY
		};
		const position = this.coordToPosition(coord);

		e.preventDefault();
		e.stopPropagation();

		this.props.onScale(factor, position);
	};

	handleContextMenu = (e) => {
		e.preventDefault();
	};

	componentDidMount() {
		if (this.props.onInteractStart &&
		    this.props.onInteractUpdate &&
		    this.props.onInteractEnd &&
		    this.props.onInteractCancel
		) {
			this.canvas.addEventListener('mousedown', this.handleMouseDown);
			this.canvas.addEventListener('mousemove', this.handleMouseMove);
			this.canvas.addEventListener('mouseout', this.handleMouseOut);
			this.canvas.addEventListener('mouseup', this.handleMouseUp);
		}

		if (this.props.onScale) {
			this.canvas.addEventListener('mousewheel', this.handleMouseWheel);
		}

		this.canvas.addEventListener('contextmenu', this.handleContextMenu);

		this.mounted = true;

		const update = () => {
			// Loop while the component is mounted:
			if (this.mounted) {
				window.requestAnimationFrame(update);
				this.updateCanvas();
			}
		};

		update();
	}

	componentWillUnmount() {
		this.mounted = false;

		if (this.props.onInteractStart &&
		    this.props.onInteractUpdate &&
		    this.props.onInteractEnd &&
		    this.props.onInteractCancel
		) {
			this.canvas.removeEventListener('mousedown', this.handleMouseDown);
			this.canvas.removeEventListener('mousemove', this.handleMouseMove);
			this.canvas.removeEventListener('mouseout', this.handleMouseOut);
			this.canvas.removeEventListener('mouseup', this.handleMouseUp);
		}

		if (this.props.onScale) {
			this.canvas.addEventListener('mousewheel', this.handleMouseWheel);
		}

		this.canvas.removeEventListener('contextmenu', this.handleContextMenu);
	}

	shouldComponentUpdate(nextProps) {
		if (this.props.containerWidth !== nextProps.containerWidth) {
			return true;
		}
		if (this.props.containerHeight !== nextProps.containerHeight) {
			return true;
		}

		return false;
	}

	updateCanvas() {
		const selection = this.props.selection;
		const renderState = this.props.renderState;
		const scale = this.props.scale;
		const center = this.props.center;
		const width = this.props.containerWidth;
		const height = this.props.containerHeight;
		const context = this.ctx;

		const graph = renderState.graph;
		const startPosition = renderState.buildState.startPosition;
		const endPosition = renderState.buildState.endPosition;

		if (!context) {
			return;
		}

		const viewportSize = {
			x: width,
			y: height
		};

		const translation = Vector.add(
			Vector.invert(
				Vector.multiply(center, scale)
			),
			Vector.divide(viewportSize, 2)
		);

		context.save();
		context.scale(window.devicePixelRatio, window.devicePixelRatio);

		context.clearRect(0, 0, width, height);

		context.translate(translation.x, translation.y);
		context.scale(scale, scale);

		// Draw industries:
		renderState.industries.forEach((industry, handle) => {
			context.fillStyle = 'rgba(150, 150, 200, 0.6)';
			context.beginPath();
			context.moveTo(industry.position.x, industry.position.y);
			context.arc(industry.position.x, industry.position.y, 5, 0, 2 * Math.PI);
			context.closePath();
			context.fill();

			context.lineWidth = 0.5;
			context.strokeStyle = 'rgba(150, 150, 200, 0.3)';

			if (selection &&
			    selection.type === ItemType.INDUSTRY &&
			    selection.handle === handle
			) {
				context.fillStyle = 'rgba(150, 150, 200, 0.05)';
			} else {
				context.fillStyle = 'rgba(150, 150, 200, 0.1)';
			}
			context.beginPath();
			context.moveTo(industry.position.x + industry.radius, industry.position.y);
			context.arc(industry.position.x, industry.position.y, industry.radius, 0, 2 * Math.PI);
			context.closePath();
			context.fill();
			context.stroke();
		});

		// Draw the "temp" start and end vertices and edge:
		if (startPosition && endPosition) {
			context.lineWidth = 2 / scale;
			context.beginPath();
			context.moveTo(startPosition.x, startPosition.y);
			context.lineTo(endPosition.x, endPosition.y);

			context.strokeStyle = 'rgba(200, 200, 200, 1.0)';
			context.stroke();
		}

		context.beginPath();
		if (startPosition) {
			context.moveTo(startPosition.x, startPosition.y);
			context.arc(startPosition.x, startPosition.y, 5 / scale, 0, 2 * Math.PI);
		}
		if (endPosition) {
			context.moveTo(endPosition.x, endPosition.y);
			context.arc(endPosition.x, endPosition.y, 5 / scale, 0, 2 * Math.PI);
		}
		context.closePath();

		context.fillStyle = 'rgba(150, 150, 150, 1.0)';
		context.fill();

		// Draw the "final" vertices and edges:
		graph.edges.forEach((edge, handle) => {
			const curve = renderState.edgeCurves[handle];
			const arrowPosition = Bezier.point(curve, 0.5);
			const arrowNormal = Vector.normalize(Vector.subtract(Bezier.point(curve, 0.51), Bezier.point(curve, 0.49)));
			const arrowTangent = Vector.rotate(arrowNormal, Math.PI * 0.5);
			const textPosition = Vector.add(arrowPosition, Vector.multiply(arrowTangent, 7));
			const arrowSize = 5 / scale;

			let color = 'rgba(150, 150, 150, 1.0)';
			let lineWidth = 2 / scale;

			// Draw edge:
			if (renderState.stations[handle]) {
				lineWidth = 4 / scale;
				color = 'rgba(235, 200, 70, 1.0)';
			} else if (renderState.edgeIsTaken[handle]) {
				color = 'rgba(120, 235, 70, 1.0)';
			}

			context.lineWidth = lineWidth;
			context.strokeStyle = color;

			context.beginPath();
			context.moveTo(curve.start.x, curve.start.y);
			context.bezierCurveTo(curve.cp1.x, curve.cp1.y, curve.cp2.x, curve.cp2.y, curve.end.x, curve.end.y);
			context.stroke();

			// Draw edge handle:
			context.font = `${10}px sans-serif`;
			context.textAlign = 'center';
			context.textBaseline = 'middle';
			context.fillStyle = '#888';
			context.fillText(handle, textPosition.x, textPosition.y);

			// Draw direction arrow:
			context.fillStyle = color;
			context.save();
			context.translate(arrowPosition.x, arrowPosition.y);

			context.beginPath();
			context.moveTo(-(arrowNormal.x * arrowSize) + (arrowTangent.x * arrowSize), -(arrowNormal.y * arrowSize) + (arrowTangent.y * arrowSize));
			context.lineTo((arrowNormal.x * arrowSize), arrowNormal.y * arrowSize);
			context.lineTo(-(arrowNormal.x * arrowSize) - (arrowTangent.x * arrowSize), -(arrowNormal.y * arrowSize) - (arrowTangent.y * arrowSize));
			context.closePath();
			context.fill();
			context.restore();
		});

		graph.vertices.forEach((vertex, handle) => {
			const pointRadius = 5 / scale;

			// Draw control line:
			context.lineWidth = 1.5 / scale;
			context.strokeStyle = 'rgba(220, 220, 220, 1)';
			context.beginPath();
			context.moveTo(vertex.controlIn.x, vertex.controlIn.y);
			context.lineTo(vertex.controlOut.x, vertex.controlOut.y);
			context.stroke();

			// Draw vertex:
			context.beginPath();
			context.moveTo(vertex.position.x, vertex.position.y);
			context.arc(vertex.position.x, vertex.position.y, pointRadius, 0, 2 * Math.PI);
			context.closePath();

			if (renderState.vertexEdgeCount[handle] > 1) {
				context.fillStyle = 'rgba(50, 50, 50, 1.0)';
			} else {
				context.fillStyle = 'rgba(150, 150, 150, 1.0)';
			}
			context.fill();

			// Draw control points:
			context.fillStyle = 'rgba(200, 200, 200, 1.0)';
			context.beginPath();
			context.moveTo(vertex.controlIn.x, vertex.controlIn.y);
			context.arc(vertex.controlIn.x, vertex.controlIn.y, pointRadius, 0, 2 * Math.PI);

			context.moveTo(vertex.controlOut.x, vertex.controlOut.y);
			context.arc(vertex.controlOut.x, vertex.controlOut.y, pointRadius, 0, 2 * Math.PI);
			context.closePath();
			context.fill();
		});

		// Draw trains:
		renderState.trains.forEach((train, trainHandle) => {
			const carriagePoints = renderState.trainCarriagePoints[trainHandle];
			const color = {
				r: 100,
				g: 150,
				b: 200,
				a: 1
			};

			let carriageIndex = 0;
			let startPosition = carriagePoints[0];

			if (selection &&
			    selection.type === ItemType.TRAIN &&
			    selection.handle === trainHandle
			) {
				color.r += 20;
				color.g += 20;
				color.b += 20;
			}

			carriagePoints.slice(1).forEach((position) => {
				const normal = Vector.normalize(
					Vector.subtract(
						startPosition,
						position
					)
				);
				const carriage = train.carriages[carriageIndex];
				const carriageType = renderState.carriageTypes[carriage.type];
				const fillFactor = carriage.items / carriageType.capacity;
				const tangent = Vector.rotate(normal, Math.PI * 0.5);
				const widthOffset = Vector.multiply(tangent, carriageType.width * 0.5);
				const lengthOffset = Vector.multiply(normal, carriageType.length);
				const cargoOffset = Vector.multiply(lengthOffset, fillFactor);

				// Carriage:
				if (carriageType.capacity === 0 || carriage.items < carriageType.capacity) {
					context.save();
					context.fillStyle = `rgba(${color.r + 20}, ${color.g + 20}, ${color.b + 20}, ${color.a})`;

					context.translate(startPosition.x, startPosition.y);

					context.beginPath();
					context.moveTo(widthOffset.x, widthOffset.y);
					context.lineTo(-widthOffset.x, -widthOffset.y);
					context.lineTo(-widthOffset.x - lengthOffset.x, -widthOffset.y - lengthOffset.y);
					context.lineTo(widthOffset.x - lengthOffset.x, widthOffset.y - lengthOffset.y);
					context.closePath();
					context.fill();
					context.restore();
				}

				// Cargo fill percentage:
				if (carriage.items > 0) {
					context.save();
					context.fillStyle = `rgba(${color.r - 20}, ${color.g - 20}, ${color.b - 20}, ${color.a})`;

					context.translate(startPosition.x, startPosition.y);

					context.beginPath();
					context.moveTo(widthOffset.x, widthOffset.y);
					context.lineTo(-widthOffset.x, -widthOffset.y);
					context.lineTo(-widthOffset.x - cargoOffset.x, -widthOffset.y - cargoOffset.y);
					context.lineTo(widthOffset.x - cargoOffset.x, widthOffset.y - cargoOffset.y);
					context.closePath();
					context.fill();
					context.restore();
				}

				startPosition = position;
				carriageIndex += 1;
			});
		});

		context.restore();
	}

	render() {
		const {classes} = this.props;

		return <canvas
			className={classes.canvas}
			width={this.props.containerWidth * window.devicePixelRatio}
			height={this.props.containerHeight * window.devicePixelRatio}
			ref={(canvas) => {
				console.log('Creating canvas', canvas);
				if (canvas) {
					this.canvas = canvas;
					this.ctx = canvas.getContext('2d');
				} else {
					this.canvas = null;
					this.ctx = null;
				}
			}}
			moz-opaque
		></canvas>;
	}
}

export default injectSheet(styles)(
	Dimensions()(
		CanvasRendererComponent
	)
);


export default {
	SELECT_ITEM: 1,
	TAG_ITEM: 2,
	CREATE_EDGE: 3,
	MOVE_VERTEX: 4,
	PLACE_TRAIN: 5,
	PLACE_INDUSTRY: 6,
	PLACE_STATION: 7,
	ADD_TRAIN_DESTINATION: 8
};

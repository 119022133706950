import ControllerMode from '../ControllerMode';
import Interface from './Interface';
import ItemType from '../../ItemType';
import Logistics from '../../Simulation';
import React, {Component} from 'react';
import TrainState from '../../TrainState';
import Timer from '../../../engine/Timer';
import Vector from '../../../engine/math/vector.js';

const STORAGE_KEY = 'logistics.states';

const loadStates = () => {
	let states = JSON.parse(localStorage.getItem(STORAGE_KEY));

	if (!states) {
		states = [];
	}

	// 'undefined' gets serialized to 'null' by JSON, so we'll have to
	// loop trough and delete any null entries:
	states.forEach((state) => {
		state.edges.forEach((value, index) => {
			if (value === null) {
				delete state.edges[index];
			}
		});
		state.vertices.forEach((value, index) => {
			if (value === null) {
				delete state.vertices[index];
			}
		});
	});

	return states;
};

const storeStates = (states) => {
	localStorage.setItem(STORAGE_KEY, JSON.stringify(states));
};

class InterfaceContainer extends Component {
	mounted = false;
	simulation = new Logistics();
	timer = new Timer({
		frequency: 60
	});

	state = {
		viewportScale: 2.5,
		viewportCenter: {
			x: 0,
			y: 0
		},

		states: loadStates(),
		renderState: this.simulation.getRenderState(),

		selection: null,

		mode: ControllerMode.SELECT_ITEM,
		modeContext: null
	};

	doFrame() {
		this.timer.tick();

		// Step the simulation:
		let simSteps = this.timer.simTickCount();

		if (simSteps) {
			while (simSteps--) {
				this.simulation.update(this.timer.simTickTime());
			}

			this.setState({
				renderState: this.simulation.getRenderState()
			});
		}
	}

	componentDidMount() {
		this.mounted = true;

		const update = () => {
			// Loop while the component is mounted:
			if (this.mounted) {
				window.requestAnimationFrame(update);
				this.doFrame();
			}
		};

		update();
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	handleCenterViewport = (position) => {
		this.setState({
			viewportCenter: position
		});
	};

	handleInteractStart = (position) => {
		if (this.state.mode === ControllerMode.CREATE_EDGE) {
			this.simulation.startBuild(position);
		} else if (this.state.mode === ControllerMode.MOVE_VERTEX) {
			this.simulation.startMove(position);
		}

		this.setState({
			renderState: this.simulation.getRenderState()
		});
	};

	handleInteractUpdate = (position) => {
		if (this.state.mode === ControllerMode.CREATE_EDGE) {
			this.simulation.updateBuild(position);
		} else if (this.state.mode === ControllerMode.MOVE_VERTEX) {
			this.simulation.updateMove(position);
		}

		this.setState({
			renderState: this.simulation.getRenderState()
		});
	};

	handleInteractEnd = (position) => {
		let handle = null;
		let item = null;

		switch (this.state.mode) {
		case ControllerMode.SELECT_ITEM:
			item = this.simulation.getItemAt(position);
			if (item) {
				this.setState({
					selection: item
				});
			} else {
				this.setState({
					selection: null
				});
			}
			break;
		case ControllerMode.TAG_ITEM:
			throw new Error('Not implemented');
		case ControllerMode.CREATE_EDGE:
			this.simulation.completeBuild(position);
			this.setState({
				renderState: this.simulation.getRenderState()
			});
			break;
		case ControllerMode.MOVE_VERTEX:
			this.simulation.completeMove(position);
			this.setState({
				renderState: this.simulation.getRenderState()
			});
			break;
		case ControllerMode.PLACE_TRAIN:
			handle = this.simulation.placeTrain(position);
			if (handle !== null) {
				this.setState({
					renderState: this.simulation.getRenderState(),
					selection: {
						type: ItemType.TRAIN,
						handle: handle
					}
				});
			}
			break;
		case ControllerMode.PLACE_INDUSTRY:
			handle = this.simulation.placeIndustry({
				position: position
			});
			if (handle !== null) {
				this.setState({
					renderState: this.simulation.getRenderState(),
					selection: {
						type: ItemType.INDUSTRY,
						handle: handle
					}
				});
			}
			break;
		case ControllerMode.PLACE_STATION:
			this.simulation.placeStation(position);
			this.setState({
				renderState: this.simulation.getRenderState()
			});
			break;
		case ControllerMode.ADD_TRAIN_DESTINATION:
			handle = this.simulation.getEdgeAtPosition(position);
			if (handle !== null) {
				this.simulation.addDestination(this.modeContext.handle, handle);
			}
			this.setState({
				renderState: this.simulation.getRenderState()
			});
			break;
		}
	};

	handleInteractCancel = () => {
		switch (this.state.mode) {
		case ControllerMode.CREATE_EDGE:
			this.simulation.cancelBuild();
			this.setState({
				renderState: this.simulation.getRenderState()
			});
			break;
		case ControllerMode.MOVE_VERTEX:
			this.simulation.cancelMove();
			this.setState({
				renderState: this.simulation.getRenderState()
			});
			break;
		}
	};

	handleViewportTranslate = (translation) => {
		const currentCenter = this.state.viewportCenter;

		this.setState({
			viewportCenter: Vector.add(currentCenter, translation)
		});
	};

	handleTimeMultiplierIncrease = () => {
		this.timer.timeMultiplier /= 0.5;
	};

	handleTimeMultiplierDecrease = () => {
		this.timer.timeMultiplier *= 0.5;
	};

	handleDeleteSelection = () => {
		if (this.state.selection !== null && this.state.selection.type === ItemType.EDGE) {
			// Actually delete the edge:
			this.simulation.deleteEdge(this.state.selection.handle);

			// Make sure we're not keeping a reference to the edge we
			// just deleted:
			this.setState({
				selection: null,
				renderState: this.simulation.getRenderState()
			});
		}
	};

	handleSelectIndustry = (handle) => {
		this.setState({
			selection: {
				type: ItemType.INDUSTRY,
				handle: handle
			}
		});
	};

	handleSelectTrain = (handle) => {
		this.setState({
			selection: {
				type: ItemType.TRAIN,
				handle: handle
			}
		});
	};

	handleSetMode = (mode, context = null) => {
		this.setState({
			mode: mode,
			modeContext: context
		});
	};

	handleStateLoad = (stateIndex) => {
		const states = loadStates();
		const state = states[stateIndex];

		this.simulation.setState(state);

		this.setState({
			states: states,
			renderState: this.simulation.getRenderState()
		});
	};

	handleStateRemove = (stateIndex) => {
		const states = loadStates();

		states.splice(stateIndex, 1);
		storeStates(states);

		this.setState({
			states: states
		});
	};

	handleStateAddCurrent = () => {
		const states = loadStates();

		states.push(this.simulation.getState());

		storeStates(states);

		this.setState({
			states: states
		});
	};

	handleStatesClear = () => {
		const states = [];

		storeStates(states);

		this.setState({
			states: states
		});
	};

	handleTrainRemoveDestination = (handle, index) => {
		this.simulation.removeDestination(handle, index);

		this.setState({
			renderState: this.simulation.getRenderState()
		});
	};

	handleTrainToggle = (handle) => {
		const train = this.state.renderState.trains[handle];

		if (train.state === TrainState.IDLE ||
		    train.state === TrainState.STOP
		) {
			this.simulation.startTrain(handle);
		} else {
			this.simulation.stopTrain(handle);
		}

		this.setState({
			renderState: this.simulation.getRenderState()
		});
	};

	render() {
		return <Interface
			renderState={this.state.renderState}

			selection={this.state.selection}
			mode={this.state.mode}
			modeContext={this.state.modeContext}

			viewportScale={this.state.viewportScale}
			viewportCenter={this.state.viewportCenter}
			onInteractStart={this.handleInteractStart}
			onInteractUpdate={this.handleInteractUpdate}
			onInteractEnd={this.handleInteractEnd}
			onInteractCancel={this.handleInteractCancel}
			onViewportTranslate={this.handleViewportTranslate}

			averageDt={this.timer.averagedTickTime()}
			updateFrequency={this.timer.updateFrequency}
			timeMultiplier={this.timer.timeMultiplier}
			cpuReliefFactor={this.timer.cpuReliefFactor}
			accumulatedSimTime={this.timer.accumulatedSimTime}

			onTimeMultiplierDecrease={this.handleTimeMultiplierDecrease}
			onTimeMultiplierIncrease={this.handleTimeMultiplierIncrease}
			onDeleteSelection={this.handleDeleteSelection}
			onSelectIndustry={this.handleSelectIndustry}
			onSelectTrain={this.handleSelectTrain}
			onSetMode={this.handleSetMode}

			onCenterViewport={this.handleCenterViewport}
			onTrainRemoveDestination={this.handleTrainRemoveDestination}
			onTrainToggle={this.handleTrainToggle}

			states={this.state.states}
			onStateLoad={this.handleStateLoad}
			onStateRemove={this.handleStateRemove}
			onStateAddCurrent={this.handleStateAddCurrent}
			onStatesClear={this.handleStatesClear}
		/>;
	}
}

export default InterfaceContainer;

import CanvasRenderer from './CanvasRenderer';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import injectSheet from 'react-jss';

const styles = {
	viewport: {
		position: 'relative',
		height: '150px',
		overflow: 'hidden',

		backgroundColor: 'rgba(250, 250, 250, 1.0)',
		marginBottom: '8px'
	},

	viewportButtons: {
		composes: 'btn-group',
		position: 'absolute',
		top: '5px',
		right: '5px'
	},

	viewportButton: {
		composes: 'btn btn-secondary'
	}
};

class IndustryDetails extends Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		handle: PropTypes.number.isRequired,
		renderState: PropTypes.object.isRequired,
		selection: PropTypes.object,
		onCenterViewport: PropTypes.func.isRequired
	};

	render() {
		const {classes} = this.props;
		const industry = this.props.renderState.industries[this.props.handle];

		return (
			<Fragment>
				<h3>Industry {this.props.handle}</h3>
				<div className={classes.viewport}>
					<div className={classes.viewportButtons}>
						<button
							className={classes.viewportButton}
							onClick={() => {
								this.props.onCenterViewport(industry.position);
							}}
						>
							<i className="fa fa-search"></i>
						</button>
					</div>

					<CanvasRenderer
						selection={this.props.selection}
						renderState={this.props.renderState}

						scale={2}
						center={industry.position}
					/>
				</div>

				<dl>
					<dt>Handle</dt><dd>{this.props.handle}</dd>
					<dt>Productivity</dt><dd>{industry.productivity}</dd>
					<dt>Storage capacity</dt><dd>{industry.maxItems}</dd>
					<dt>Waiting</dt><dd>{industry.items}</dd>
				</dl>
			</Fragment>
		);
	}
}

export default injectSheet(styles)(IndustryDetails);

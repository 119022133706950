const clamp = (min, max, value) => {
	return Math.min(max, Math.max(min, value));
};

const lerp = (from, to, alpha) => {
	return from + ((to - from) * alpha);
};

export default {
	clamp,
	lerp
};
